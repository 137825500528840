<!-- Add Item modal start here  -->
<p-dialog [showHeader]="false" [(visible)]="urlModal" [modal]="true" [baseZIndex]="10000"
          [draggable]="false" [resizable]="false" styleClass="cart_modal modal_576_cart add_item_fh_step1 animate_wrap " (onShow)="onModalShow()"
          (onHide)="onURLModalHide()" transitionOptions="0.2s ease-in-out" maskStyleClass="mask-class-2 add_item_1 animate_wrap_mask">
<!--  Local loader div for url modal-->
  <div class="loader_wrapper" *ngIf="modalLoader">
    <div class="loader_positioning">
      <div class="loader2"></div>
    </div>
  </div>

  <div class="modal-content">
    <div class="modal-header">
      <div class="heading_wrap">
<!--        <h2 class="heading3">Paste the product listing URL</h2>-->
        <h2 class="heading3" *ngIf="type == 'quote'">Add Item to Quote</h2>
        <h2 class="heading3" *ngIf="type == 'cart'">Add Item to Cart</h2>
        <h2 class="heading3" *ngIf="type == 'wishlist'">Save Item to Wishlist</h2>
        <h2 class="heading3" *ngIf="type == 'mobile-navbar'">Add Item</h2>
      </div>
      <button (click)="urlModal = false" type="button" class="close-btn for_desk">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.5 1.5L1.5 16.5M1.5 1.5L16.5 16.5" stroke="#96999F" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>
      </button>
      <button (click)="urlModal = false" type="button" class="close-btn for_mob">
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 2L2 17M2 2L17 17" stroke="#DC5A4B" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>
    <div class="modal-body">
      <div class="modal-body-description  cstm_error_group" [formGroup]="urlForm!">
        <!--            Modal body Description-->
        <div class="col-md-12">
          <p class="url_detail">Paste the URL of an item from any of our brand partners.</p>
          <div class="form-group add-item-url">
            <div class="fg-inner">
<!--              <label class="form-label">Product Listing URL*</label>-->
              <label class="form-label">Item URL*</label>
              <input class="form-control custom-input-1" type="text" autocapitalize="off" inputmode="url"
                     placeholder="www.example.com" autocomplete="off" [autofocus]="randomService.onDesktop" (keyup)="onURLKeyUp($event)"
                     formControlName="url" trim/>
                <span class="link_icons">
                   <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                   <path d="M12.25 10.4979C12.25 8.31766 10.2227 6.125 7.75 6.125C7.45711 6.125 6.49166 6.125 6.25 6.125C3.76472 6.125 1.75 8.08283 1.75 10.4979C1.75 12.579 3.24601 14.3206 5.25 14.7625C5.57161 14.8334 5.90631 14.8708 6.25 14.8708" stroke="#444444" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.75 10.4979C8.75 12.6782 10.7773 14.8708 13.25 14.8708C13.5429 14.8708 14.5083 14.8708 14.75 14.8708C17.2353 14.8708 19.25 12.913 19.25 10.4979C19.25 8.41682 17.754 6.67525 15.75 6.23336C15.4284 6.16245 15.0937 6.12501 14.75 6.12501" stroke="#444444" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </span>
            </div>
            <div class="error_msg_ui">
              <div class="fg-inner error_wrapper"
                   *ngIf="urlForm.controls['url'].invalid && urlForm.controls['url'].touched ">
                <small *ngIf="urlForm.controls['url'].hasError('required')" class="error-1">
                  {{errorService.FIELD_REQUIRED}}
                </small>
                <small *ngIf="urlForm.controls['url'].hasError('pattern')" class="error-1">
                  {{errorService.URL_INVALID}}
                </small>
              </div>
              <div class="fg-inner error_wrapper" *ngIf="urlForm.controls['url'].valid && brandPartnerError">
                <small class="error-1">
                  {{errorService.URL_NOT_MINOAN_BRAND_PARTNER}}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="modal-footer align-self-center">
      <button type="button" class="btn btn_primary" [disabled]="brandPartnerFetched || brandPartnerError" (click)="submitProductUrl($event)" id="scrapeProductUrl">Next</button>
    </div>
  </div>
</p-dialog>
<!-- Add Item modal close here  -->

<p-dialog [showHeader]="false" [(visible)]="detailsFetched" [modal]="true" [baseZIndex]="10000"
          [draggable]="false" [resizable]="false" styleClass="cart_modal modal_754_cart modal_754 add_item_fh_step2 animate_wrap"
          (onHide)="onAddProductModalHide()" maskStyleClass="mask-class-2 animate_wrap_mask" (onShow)="onModalShow()">
  <!--  Local loader div for url modal-->
  <div class="loader_wrapper" *ngIf="modalLoader">
    <div class="loader_positioning">
      <div class="loader2"></div>
    </div>
  </div>

  <div class="modal-content">
    <div class="modal-header">
      <div class="heading_wrap detailsModal pos_ui">
        <h2 class="heading3" *ngIf="type == 'quote'">Add Item to Quote</h2>
        <h2 class="heading3" *ngIf="type == 'cart'">Add Item to Cart</h2>
        <h2 class="heading3 heading_for_wish" *ngIf="type == 'wishlist'">Save Item to Wishlist</h2>
        <h2 class="heading3" *ngIf="type == 'mobile-navbar'">Add Item</h2>
      </div>
      <button (click)="detailsFetched = false;" type="button" class="close-btn for_desk">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.5 1.5L1.5 16.5M1.5 1.5L16.5 16.5" stroke="#96999F" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>
      </button>
      <button (click)="detailsFetched = false;" type="button" class="close-btn for_mob">
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 2L2 17M2 2L17 17" stroke="#DC5A4B" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
      <button (click)="backToUrlStep()" type="button" class="close-btn back_arrow">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0806 20.5838C15.5215 21.1387 14.6149 21.1387 14.0557 20.5838L6.41937 13.0048C5.86021 12.4499 5.86021 11.5501 6.41937 10.9952L14.0557 3.41622C14.6149 2.86126 15.5215 2.86126 16.0806 3.41622C16.6398 3.97117 16.6398 4.87093 16.0806 5.42589L9.45672 12L16.0806 18.5741C16.6398 19.1291 16.6398 20.0288 16.0806 20.5838Z" fill="#DC5A4B"/>
        </svg>
      </button>
    </div>
    <div class="modal-body">
      <div class="modal-body-description  cstm_error_group" [formGroup]="addProductForm!">
        <!--            Modal body Description-->
        <div class="row add_product_row">
          <div [ngClass]="{'col-md-12': images.length == 0, 'col-md-9 left_form_sec' : images.length > 0}">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <div class="fg-inner">
<!--                    <label class="form-label">Product Listing URL*</label>-->
                    <label class="form-label">Item URL*</label>
                    <input class="form-control custom-input-1 input_for_product" type="text" autocapitalize="off" inputmode="url"
                           placeholder="www.example.com" autocomplete="off" (keyup)="onKeyUpEventHandler($event)"
                           formControlName="url" trim />
                    <span class="link_icons">
                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                         <path d="M12.25 10.4979C12.25 8.31766 10.2227 6.125 7.75 6.125C7.45711 6.125 6.49166 6.125 6.25 6.125C3.76472 6.125 1.75 8.08283 1.75 10.4979C1.75 12.579 3.24601 14.3206 5.25 14.7625C5.57161 14.8334 5.90631 14.8708 6.25 14.8708" stroke="#444444" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8.75 10.4979C8.75 12.6782 10.7773 14.8708 13.25 14.8708C13.5429 14.8708 14.5083 14.8708 14.75 14.8708C17.2353 14.8708 19.25 12.913 19.25 10.4979C19.25 8.41682 17.754 6.67525 15.75 6.23336C15.4284 6.16245 15.0937 6.12501 14.75 6.12501" stroke="#444444" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                  </span>
                  </div>
                  <div class="fg-inner error_wrapper"
                       *ngIf="addProductFormControls['url'].invalid && addProductFormControls['url'].touched">
                    <small *ngIf="addProductFormControls['url'].hasError('required')" class="error-1">
                      {{errorService.ADD_PRODUCT_URL_INVALID}}
                    </small>
                    <small *ngIf="addProductFormControls['url'].hasError('pattern')" class="error-1">
                      {{errorService.ADD_PRODUCT_URL_INVALID}}
                    </small>
                  </div>
                  <div class="fg-inner error_wrapper" *ngIf="addProductFormControls['url'].touched">
                    <small *ngIf="brandPartnerError" class="error-1">
                      {{errorService.URL_NOT_MINOAN_BRAND_PARTNER}}
                    </small>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <div class="fg-inner">
                    <label class="form-label">Product Name*</label>
                    <input class="form-control custom-input-1" type="text" placeholder="" autocomplete="off"
                           formControlName="productName" (keyup)="onKeyUpEventHandler($event)"/>
                  </div>
                  <div class="fg-inner error_wrapper"
                       *ngIf="addProductFormControls['productName'].invalid && addProductFormControls['productName'].touched">
                    <small *ngIf="addProductFormControls['productName'].hasError('required')" class="error-1">
                      {{errorService.ADD_PRODUCT_PRODUCT_NAME_REQUIRED}}
                    </small>
                  </div>
                </div>
              </div>
              <div class="col-md-9">
                <div class="form-group" [ngClass]="{'error_space': addProductFormControls['variant'].invalid && addProductFormControls['variant'].touched}">
                  <div class="fg-inner">
                    <label class="form-label">Color / Style / Size / Finish, etc.*
                      <div class="custom_tooltip_cart">
                    <span class="addcart_modal_icon">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="9" cy="9" r="6.75" stroke="#444444" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"/>
                      <path
                        d="M9 9C9.82843 9 10.5 8.32843 10.5 7.5C10.5 6.67157 9.82843 6 9 6C8.17157 6 7.5 6.67157 7.5 7.5"
                        stroke="#444444" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M9 11.25H9.00833" stroke="#444444" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round"/>
                    </svg>
                  </span>
                        <div class="tooltip_content">
                          <div class="cstm_tooltip_inner">If an item has more than one possible variation, please specify a value for each.
                          </div>
                        </div>
                      </div>
                    </label>
                    <input class="form-control custom-input-1 " autocomplete="off" type="text"
                           placeholder="{{variantPlaceholder}}" formControlName="variant" (keyup)="onKeyUpEventHandler($event)"/>
                  </div>
                  <div class="fg-inner add_item_modal_checkbox">
                    <div class="custom-checkbox-group">
                      <p-checkbox styleClass="custom-checkbox-button" inputId="no-variant-checkbox" [binary]="true" formControlName="noVariant"></p-checkbox>
                      <label class="checkbox-button-label" for="no-variant-checkbox">This item does not offer any variations.</label>
                    </div>
                  </div>
                  <div class="fg-inner error_wrapper"
                       *ngIf="addProductFormControls['variant'].invalid && addProductFormControls['variant'].touched">
                    <small *ngIf="addProductFormControls['variant'].hasError('required')" class="error-1">
                      {{errorService.ADD_PRODUCT_VARIANT_REQUIRED}}
                    </small>
                  </div>
                </div>
              </div>
              <div class="col-md-3 cart_quantity_section_modal for_add_item ">
                <div class="form-group ">
                  <div class="fg-inner">
                    <label class="form-label cart_quantity">Quantity*</label>
                    <div class="custom_input_number">
                      <app-qty-input formControlName="quantity" [min]="1"
                                     decrementButtonClass="p-button-danger"
                                     [decrementButtonIcon]="addProductFormControls['quantity'].value == 1 ? 'pi pi-minus disabled' : 'pi pi-minus'"
                                     incrementButtonClass="p-button-success" incrementButtonIcon="pi pi-plus"
                                     decrementButtonIcon="pi pi-minus" (enterKeyPressed)="onKeyUpEventHandler($event)">
                      </app-qty-input>
                    </div>
                  </div>
                  <div class="fg-inner error_wrapper"
                       *ngIf="addProductFormControls['quantity'].invalid && addProductFormControls['quantity'].touched">
                    <small *ngIf="addProductFormControls['quantity'].hasError('required')" class="error-1">
                      {{errorService.FIELD_REQUIRED}}
                    </small>
                    <small *ngIf="addProductFormControls['quantity'].hasError('min')" class="error-1">
                      {{errorService.QUANTITY_GREATER_THAN_0}}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 add_item_carousal_section" *ngIf="images.length > 0">
            <product-image-carousel [images]="images" (mainImageChanged)="mainImageChangedEventHandler($event)"></product-image-carousel>
          </div>
        </div>
      </div>

    </div>
    <div class="modal-footer align-self-center p_sticky_mob">
      <button type="button" class="btn btn_secondary add_item_cancel_btn" *ngIf="type !== 'mobile-navbar'" (click)="detailsFetched = false;">Cancel</button>
      <button type="button" class="btn btn_primary" *ngIf="type == 'quote'"
              [disabled]="disableAddButton" appDoubleClick
              (throttledClick)="submitAddProduct()">Add to Quote
      </button>
      <button type="button" class="btn btn_primary" *ngIf="type == 'wishlist'"
              [disabled]="disableAddButton" appDoubleClick
              (throttledClick)="submitAddProduct()">Save to Wishlist
      </button>
      <button type="button" class="btn btn_primary" id="addItem" *ngIf="type == 'cart'"
              [disabled]="disableAddButton" appDoubleClick
              (throttledClick)="submitAddProduct()">Add to Cart
      </button>

      <ng-container *ngIf="type === 'mobile-navbar'">
<!--        New buttons here-->


<!--        3 buttons-->
       <div class="add_item_btn_wrap">
        <div class="cart_btn_wrapper">
          <button type="button" class="btn btn_primary w-icon" id="addItemCart"
                  [disabled]="disableAddButton" appDoubleClick
                  (throttledClick)="submitCommonAddProductModal('cart')">
            <span class="el-icon">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.76238 10.5H13.6996C14.4658 10.5 14.8489 10.5 15.1539 10.3584C15.4225 10.2336 15.6483 10.0333 15.803 9.78237C15.9787 9.49749 16.021 9.12 16.1056 8.36504L16.5482 4.41626C16.574 4.18566 16.5869 4.07036 16.5495 3.98112C16.5167 3.90274 16.458 3.83773 16.383 3.79671C16.2977 3.75 16.1807 3.75 15.9467 3.75H3.38413M1.49277 1.5H2.43722C2.6374 1.5 2.7375 1.5 2.81581 1.53774C2.88475 1.57097 2.94188 1.62418 2.97963 1.69032C3.02251 1.76545 3.02875 1.86449 3.04124 2.06257L3.72681 12.9374C3.7393 13.1355 3.74554 13.2345 3.78842 13.3097C3.82617 13.3758 3.8833 13.429 3.95224 13.4623C4.03055 13.5 4.13065 13.5 4.33083 13.5H14.3533M5.65353 16.125H5.6611M12.4621 16.125H12.4696M6.03178 16.125C6.03178 16.3321 5.86244 16.5 5.65353 16.5C5.44463 16.5 5.27528 16.3321 5.27528 16.125C5.27528 15.9179 5.44463 15.75 5.65353 15.75C5.86244 15.75 6.03178 15.9179 6.03178 16.125ZM12.8403 16.125C12.8403 16.3321 12.671 16.5 12.4621 16.5C12.2532 16.5 12.0838 16.3321 12.0838 16.125C12.0838 15.9179 12.2532 15.75 12.4621 15.75C12.671 15.75 12.8403 15.9179 12.8403 16.125Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </span>
            <p class="el-text">Add to Cart</p>
          </button>

        </div>
         <div class="quote_wish_btn_wrapper">
           <div ngbDropdown class="filter_dropdown_sec  for_item_modal dropdown" #quoteBrandsDropdown="ngbDropdown" (openChange)="onChangeDropdown($event)">
             <button type="button" class="btn add_item_quotes_btn" id="addItemQuoteDropdown" ngbDropdownToggle
                     [disabled]="disableAddButton">
               Add to Quote
             </button>
             <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="addItemQuote">
               <div class="dropdown_listing" *ngIf="quoteBrandsDropdown.isOpen()">
                 <div class="dropdown_heading">
                   <p class="inner_heading">Add to Quote</p>
                   <span class="quote_inner_back_icon" (click)="quoteBrandsDropdown.close()">
                     <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.5626 12L4.51252 12" stroke="#DC5A4B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M13.5376 20L4.51253 12L13.5376 4" stroke="#DC5A4B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                   </span>
                 </div>
                 <app-multiselect-listbox
                   [options]="activeQuotesList"
                   filterPlaceHolder="Search…"
                   [(ngModel)]="selectedQuote"
                   emptyFilterMessage="No matches found."
                   [metaKeySelection]="false"
                   [checkbox]="true"
                   [filter]="true"
                   optionLabel="name"
                   [listStyle]="{'max-height':'227px', 'min-height':'190px'}"
                   [style]="{'width':'100%'}"
                   [(dropdownClose)]="quoteBrandsDropdown._open"
                   [virtualScroll]="true"
                   [itemSize]="38"
                 >
                   <ng-template let-country  pTemplate="item">
                     <div class="country-item">
                       <div class="quote_member_name">{{country.name}}</div>
                     </div>
                   </ng-template>
                 </app-multiselect-listbox>

                 <div class="btn_wrapper">
                   <button class="btn btn_primary" appDoubleClick [disabled]="!selectedQuote || disableAddButton" (throttledClick)="submitCommonAddProductModal('quote')"  id="addItemQuote" >Confirm</button>
                   <button class="btn add_item_quotes_btn" [disabled]="disableAddButton" appDoubleClick  id="addItemNewQuote" (throttledClick)="submitCommonAddProductModal('new-quote')" >
                     <span class="el-icon">
                       <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.1667 6.5V13.8333M6.5 10.1667H13.8333M19.3333 10.1667C19.3333 15.2293 15.2293 19.3333 10.1667 19.3333C5.10406 19.3333 1 15.2293 1 10.1667C1 5.10406 5.10406 1 10.1667 1C15.2293 1 19.3333 5.10406 19.3333 10.1667Z" stroke="#444444" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </span>
                     <p class="el-text">Add to New Quote</p>
                   </button>
                 </div>
               </div>
             </div>
           </div>

           <button type="button" class="btn add_item_quotes_btn" id="addItemWishlist"
                   [disabled]="disableAddButton" appDoubleClick
                   (throttledClick)="submitCommonAddProductModal('wishlist')">
             Save to Wishlist
           </button>
         </div>
       </div>


      </ng-container>

    </div>
  </div>
</p-dialog>
