import {
  Inject,
  Injectable,
  isDevMode,
  PLATFORM_ID,
  Renderer2,
  RendererFactory2,
  ViewEncapsulation
} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {filter} from 'rxjs/operators';
import {isPlatformBrowser} from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class IntercomService {

  renderer2: Renderer2 | any;
  id: any
  config: any;
  // platformId: any;
  document: any
  constructor(
    @Inject(PLATFORM_ID) public platformId: Object,
    public router: Router,
    public rendererFactory: RendererFactory2,
  ) {
    this.config = {
      appId: 'tctf3las',
      updateOnRouterChange: false,
    };
    this.document = document;
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.renderer2 = this.rendererFactory.createRenderer(this.document, {
      id: '-1',
      encapsulation: ViewEncapsulation.None,
      styles: [],
      data: {}
    });
    // Subscribe to router changes
    if (this.config && this.config.updateOnRouterChange) {
      this.router.events.pipe(filter((
      event => event instanceof NavigationEnd))).subscribe((
      event => {
        this.update();
      }));
    }
    else if (isDevMode()) {
     //  console.warn(`
     //  Common practice in single page applications is to update whenever the route changes.
     //  ng-intercom supports this functionality out of the box just set 'updateOnRouterChange' to true in your Intercom Module config.
     //   This warning will not appear in production, if you choose not to use router updating.
     // `);
    }
  }

  /**
   * If you'd like to control when Intercom is loaded, you can use the 'boot' method.
   * This is useful in situations like a one-page Javascript based application where the user may not be logged in
   * when the page loads. You call this method with the standard intercomSettings object.
   * @param {?=} intercomData
   * @return {?}
   */
  boot(intercomData: any) {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    /** @type {?} */
    const app_id = intercomData.app_id ? intercomData.app_id : this.config.appId
      // Run load and attach to window
    ;
    // Run load and attach to window
    this.loadIntercom(this.config, (/**
     * @param {?=} event
     * @return {?}
     */
    (event: any) => {
      // then boot the intercom js
      /** @type {?} */
      const data = Object.assign({}, intercomData, { app_id });
      return this._callIntercom('boot', data);
    }));
  }
  /**
   * If you have the Respond product (combined with another product like Engage)
   * you should call the Intercom shutdown method to clear your users’ conversations anytime they logout
   * of your application. Otherwise, the cookie we use to track who was most recently logged in on a given device
   * or computer will keep these conversations in the Messenger for one week.
   * This method will effectively clear out any user data that you have been passing through the JS API.
   * @return {?}
   */
  shutdown() {
    return this._callIntercom('shutdown');
  }
  /**
   * Calling the update method without any other arguments will trigger the JavaScript to look for new messages
   * that should be displayed to the current user (the one whose details are in the window.intercomSettings variable)
   * and show them if they exist.
   *
   * Calling the update method with a JSON object of user details will update those fields on the current user
   * in addition to logging an impression at the current URL and looking for new messages for the user.
   * @param {?=} data
   * @return {?}
   */
  update(data?: any) {
    return this._callIntercom('update', data);
  }
  /**
   * This will hide the main Messenger panel if it is open. It will not hide the Messenger Launcher.
   * @return {?}
   */
  hide() {
    return this._callIntercom('hide');
  }
  /**
   * This will show the Messenger. If there are no conversations it will open with the new message view,
   * if there are it will open with the message list.
   *
   * If a `message` parameter is supplied, it will automatically open a new message window, aliasing showNewMessage().
   *
   * @param {?=} message
   * @return {?}
   */
  show(message: any) {
    if (message) {
      return this.showNewMessage(message);
    }
    return this._callIntercom('show');
  }
  /**
   * To open the message window with the message list you can call `showMessages()`.
   * @return {?}
   */
  showMessages() {
    return this._callIntercom('showMessages');
  }
  /**
   * To open the message window with the new message view you can call showNewMessage().
   *
   * This function takes an optional parameter that can be used to pre-populate the message composer as shown below.
   * @param {?=} message
   * @return {?}
   */
  showNewMessage(message: any) {
    return this._callIntercom('showNewMessage', message);
  }
  /**
   * You can submit an event using the trackEvent method.
   * This will associate the event with the currently logged in user and send it to Intercom.
   * The final parameter is a map that can be used to send optional metadata about the event.
   *
   * You can also add custom information to events in the form of event metadata.
   * @param {?} eventName
   * @param {?=} metadata
   * @return {?}
   */
  trackEvent(eventName: any, metadata: any) {
    return this._callIntercom('trackEvent', eventName, metadata);
  }
  /**
   * A visitor is someone who goes to your site but does not use the messenger.
   * You can track these visitors via the visitor user_id.
   * This user_id can be used to retrieve the visitor or lead through the REST API.
   * @return {?}
   */
  getVisitorId() {
    return this._callIntercom('getVisitorId');
  }
  /**
   * Alias for getVisitorId()
   * \@alias getVisitorId()
   * \@readonly
   * @return {?}
   */
  get visitorId() {
    return this._callIntercom('getVisitorId');
  }
  /**
   * Gives you the ability to hook into the show event. Requires a function argument.
   * @param {?} handler
   * @return {?}
   */
  onShow(handler: any) {
    return this._callIntercom('onShow', handler);
  }
  /**
   * Gives you the ability to hook into the hide event. Requires a function argument.
   * @param {?} handler
   * @return {?}
   */
  onHide(handler: any) {
    return this._callIntercom('onHide', handler);
  }
  /**
   * This method allows you to register a function that will be called when the current number of unread messages changes.
   * @param {?} handler
   * @return {?}
   */
  onUnreadCountChange(handler: any) {
    return this._callIntercom('onUnreadCountChange', handler);
  }
  /**
   * If you would like to trigger a tour based on an action a user or visitor takes in your site or application,
   * ou can use this API method. You need to call this method with the id of the tour you wish to show. The id of
   * the tour can be found in the “Use tour everywhere” section of the tour editor.
   *
   * Please note that tours shown via this API must be published and the “Use tour everywhere” section must be
   * turned on. If you're calling this API using an invalid tour id, nothing will happen.
   * @param {?} tourId
   * @return {?}
   */
  startTour(tourId: any) {
    return this._callIntercom('startTour', tourId);
  }
  /**
   * Private handler to run Intercom methods safely
   * @private
   * @param {?} fn
   * @param {...?} args
   * @return {?}
   */
  _callIntercom(fn: any, ...args: any[]) {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    if ((((window) as any)).Intercom) {
      return (((window) as any)).Intercom(fn, ...args);
    }
    return;
  }
  /**
   * @param {?} conf
   * @param {?} afterInjectCallback
   * @return {?}
   */
  injectIntercomScript(conf: any, afterInjectCallback: any) {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    // Set the window configuration to conf
    (((window) as any)).intercomSettings = conf;
    // Create the intercom script in document
    /** @type {?} */
    const s: any = this.document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = `https://widget.intercom.io/widget/${this.id}`;
    if (((/** @type {?} */ (s))).attachEvent) {
      ((/** @type {?} */ (s))).attachEvent('onload', afterInjectCallback);
    }
    else {
      s.addEventListener('load', afterInjectCallback, false);
    }
    if (this.renderer2 && this.renderer2.appendChild) {
      this.renderer2.appendChild(this.document.head, s);
    }
    ((/** @type {?} */ (window) as any)).Intercom('update', conf);
  }
  /**
   * @param {?} config
   * @param {?} afterLoadCallback
   * @return {?}
   */
  loadIntercom(config: any, afterLoadCallback: any) {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.id = config.appId;
    /** @type {?} */
    const w = ((window) as any);
    /** @type {?} */
    const ic = w.Intercom
      // Set window config for Intercom
    ;
    // Set window config for Intercom
    w.intercomSettings = config;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', config);
      afterLoadCallback();
    }
    else {
      /** @type {?} */
      const i: any = (
      function () {
        i.c(arguments);
      });
      i.q = [];
      i.c = (/**
       * @param {?} args
       * @return {?}
       */
      function (args: any) {
        i.q.push(args);
      });
      w.Intercom = i;
      this.injectIntercomScript(config, afterLoadCallback);
    }
  }

  /**
   * Show help space on Intercom
   */
  onShowHelp() {
    this._callIntercom('showSpace', 'help')
  }

  /**
   * Shut down intercom and hide the intercom launcher
   */
  hideIntercomWidget() {
    this.update({
      hide_default_launcher: true
    })
    this.shutdown()
  }

}
