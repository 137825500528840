import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CheckboxModule} from 'primeng/checkbox';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {InputMaskModule} from 'primeng/inputmask';
import {TooltipModule} from 'primeng/tooltip';
import {MultiSelectModule} from "primeng/multiselect";
import {NgxTrimDirectiveModule} from "ngx-trim-directive";
import {AutoCompleteModule} from "primeng/autocomplete";
import {ListboxModule} from 'primeng/listbox';
import {InputTextModule} from "primeng/inputtext";
import {
  NgbDropdownModule,
  NgbPaginationModule,
  NgbPopoverModule,
  NgbToastModule,
  NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import {AccordionModule} from 'primeng/accordion';
import {LoaderComponent} from './loader/loader.component';
import {ToastComponent} from './toast/toast.component';
import {RouterModule} from "@angular/router";

@NgModule({
  declarations: [
    LoaderComponent,
    ToastComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    CheckboxModule,
    TooltipModule,
    DropdownModule,
    InputMaskModule,
    DialogModule,
    NgxTrimDirectiveModule,
    AutoCompleteModule,
    ListboxModule,
    InputTextModule,
    NgbDropdownModule,
    AccordionModule,
    NgbPaginationModule,
    NgbToastModule,
    NgOptimizedImage,
    NgbTooltipModule,
    NgbPopoverModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    CheckboxModule,
    TooltipModule,
    DropdownModule,
    InputMaskModule,
    DialogModule,
    MultiSelectModule,
    NgxTrimDirectiveModule,
    AutoCompleteModule,
    ListboxModule,
    NgbDropdownModule,
    AccordionModule,
    LoaderComponent,
    NgbPaginationModule,
    ToastComponent,
    NgOptimizedImage,
    NgbTooltipModule,
    NgbPopoverModule,
  ]
})
export class SharedModule {
}
