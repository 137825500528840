import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AuthService} from "../services/auth/auth.service";
import {ToastService} from "../services/misc/toast.service";
import {RandomService} from "../services/misc/random.service";
import {LOCAL_STORAGE_INFO_VARIABLE} from "../constants/constants";
import {IntercomService} from "../services/misc/intercom.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router, private auth: AuthService, private toastService: ToastService, private randomService: RandomService, public intercom: IntercomService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            console.log(err)
            if (err.status === 0) {
              if(!err.url.includes('scrap')) {
                this.toastService.showErrorToast('Something went wrong');
              }
            }
            if (err.status === 401) {
              this.randomService.updateLoader(false);
              if(localStorage.getItem(LOCAL_STORAGE_INFO_VARIABLE) !== null){
                if(!err.url.includes('accessTokenLogin')) {
                  this.auth.logoutUser()
                }
              } else {
                this.router.navigate(['/auth']).then()
                this.intercom.shutdown();
              }
            }
            if(err.status === 403){
              this.toastService.showErrorToast(err.statusText);
                this.router.navigate(['/']);
            }
            else if ([504].indexOf(err.status) !== -1) {
              this.toastService.showErrorToast('Slow internet detected');
            }


            if (err.error instanceof Blob && err.error.type === "application/json") {
              // When request of type Blob, the error is also in Blob instead of object of the json data
              return new Promise<any>((resolve, reject) => {
                let reader = new FileReader();
                reader.onload = (e: Event) => {
                  try {
                    const errmsg = JSON.parse((<any>e.target).result);
                    const error = new HttpErrorResponse({
                      error: errmsg,
                      headers: err.headers,
                      status: err.status,
                      statusText: err.statusText,
                    })
                    reject(error);
                  } catch (e) {
                    reject(err);
                  }
                };
                reader.onerror = (e) => {
                  reject(err);
                };
                reader.readAsText(err.error);
              });
            }

            if(err.status === 400){
              if(request.url.includes('/cart/item/import') || request.url.includes('/quote/item/import/b2b')){
                return throwError(err);
              }
            }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }
}

