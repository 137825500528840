import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  of,
  switchMap,
  take,
  timeout,
  timer
} from "rxjs";
import {environment} from "../../../environments/environment";
import {ValidationErrors} from "@angular/forms";
import {returnLinkAsHttp} from "../../utils/common-functions";
import {CartWishlistItemList} from "../../interfaces/cart-wishlist-brandInfoForm.type";

export interface CartProductAdd {
	productName: string,
	quantity: number;
	url: string;
	variant: string;
	companyId: number;
	productImageUrl: string,
}

export interface CartProductEdit {
	productName: string,
	quantity: number;
	url: string;
	variant: string;
	companyId: number;
	id: number;
	productImageUrl?: string,
}

export interface CartSingleItemDelete {
	id: number,
}

export interface CartSingleItemMoveToWishlist {
	id: number
	companyId: number
}

export interface CartMultipleItemMoveToWishlist {
	companyId: number;
	idList: { id: number }[]
}

export interface CartMultipleItemDelete {
	companyId: number;
	idList: { id: number }[]
}

export interface CartDetailsResponse {
	count: number,
	cartItemList: CartWishlistItemList[],
	totalQuantity: number
}

@Injectable({
	providedIn: 'root'
})
export class CartService {

	constructor(public http: HttpClient) {
	}

	/**
	 * [POST] API to add new item to cart
	 * @param body Request body
	 */
	submitAddProduct(body: CartProductAdd): Observable<any> {
		return this.http.post(`${environment.apiUrl}/cart/add`, body)
			.pipe(take(1), map((response: any) => response.data), catchError((err) => {
				throw err
			}))
	}

	/**
	 * [POST] API to get cart details
	 * @param body Request body
	 */
	getCartDetails(body: any): Observable<CartDetailsResponse> {
		return this.http.post(`${environment.apiUrl}/cart/list`, body)
			.pipe(take(1), map((response: any) => response.data), catchError((err) => {
				throw err
			}))
	}

	/**
	 * [PUT] API to edit cart item
	 * @param body Request body
	 */
	submitEditProduct(body: CartProductEdit): Observable<any> {
		return this.http.put(`${environment.apiUrl}/cart/edit`, body)
			.pipe(take(1), map((response: any) => response.data), catchError((err) => {
				throw err
			}))
	}

	/**
	 * [DELETE] API to remove the item from cart
	 * @param body Request body
	 */
	submitDeleteItemCart(body: CartSingleItemDelete) {
		return this.http.delete(`${environment.apiUrl}/cart/delete?id=${body.id}`)
			.pipe(take(1), map((response: any) => response.data), catchError((err) => {
				throw err
			}))
	}

	/**
	 * [POST] API to move single product to wishlist
	 * @param body Request body
	 */
	moveSingleProductToWishlist(body: CartSingleItemMoveToWishlist) {
		return this.http.post(`${environment.apiUrl}/cart/save`, body)
			.pipe(take(1), map((response: any) => response.data), catchError((err) => {
				throw err
			}))
	}

	/**
	 * [POST] API to move multiple products to wishlist
	 * @param body Request body
	 */
	moveBulkProductsToWishlist(body: CartMultipleItemMoveToWishlist) {
		return this.http.post(`${environment.apiUrl}/cart/multiselect/save`, body)
			.pipe(take(1), map((response: any) => response.data), catchError((err) => {
				throw err
			}))
	}

	/**
	 * [POST] API to remove the multiple item from cart
	 * @param body Request body
	 */
	deleteBulkProducts(body: CartMultipleItemDelete) {
		return this.http.post(`${environment.apiUrl}/cart/multiple/delete`, body)
			.pipe(take(1), map((response: any) => response.data), catchError((err) => {
				throw err
			}))
	}

	/**
	 * [POST] API to check whether product is from a Minoan Partner brand or not using URL of the product
	 * @param body Request body
	 */
	checkMinoanPartner(body: { url: string }) {
		return this.http.post(`${environment.apiUrl}/brand/url/validation`, body)
	}

	/**
	 * Async validator to verify whether URL is of a Minoan Partner brand or not
	 */
	checkMinoanPartnerFormValidator() {
		return (formControl: any): Observable<ValidationErrors | null> => {
			const requestBody = {
				url: formControl!.value?.trim()
			}
			return timer(500).pipe(switchMap(() => {
				return this.checkMinoanPartner(requestBody)
					.pipe(debounceTime(500), distinctUntilChanged(), map((response: any) => {
						const data = response.data;
						if (data.isMinoanPartner) {
							return null;
							// Name available, no error
						} else {
							// Name not available
							return {
								notMinoanPartner: true
							}
						}
					}), catchError(() => {
						return of({
							notMinoanPartner: true
						})
					}))
			}))
		}
	}

	/**
	 * [POST] API to submit cart and create a quote
	 * @param body Request body
	 */
	createQuoteFromCart(body: any) {
		return this.http.post(`${environment.apiUrl}/order/create`, body)
			.pipe(take(1), map((response: any) => response.data), catchError((err) => {
				throw err
			}))
	}

	/**
	 * [GET] API to get options for cart
	 */
	getCartOptions() {
		return this.http.get(`${environment.apiUrl}/cart/option/list`)
			.pipe(take(1), map((response: any) => response.data), catchError((err) => {
				throw err
			}))
	}

	/**
	 * [POST] API to upload a CSV file and import items
	 * @param body holds the value of form data created after uploading file
	 */
	uploadItemsCSV(body: any) {
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'multipart/form-data');
		headers.append('Accept', 'application/json');
		return this.http.post<any>(`${environment.apiUrl}/cart/item/import`, body, {headers})
			.pipe(take(1), map((response: any) => response), catchError((err) => {
				throw err
			}));
	}

	/**
	 * [POST] API to fetch scraped data of product
	 * @param body Request Body
	 */
	scrapProductData(body: { url: string }) {
		const requestBody = {
			url: returnLinkAsHttp(body.url)
		};
		return this.http.post<any>(`${environment.apiUrl}/extension/scrap`, requestBody)
			.pipe(take(1), map((response: any) => response.data), catchError((err) => {
				throw err
			}), timeout({each: 15000, with: () => of({title: "",images: [], url: body.url})}));
	}

}
