import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {User} from "../auth/auth.service";
import {GettingStartedService} from "../misc/getting-started.service";
import {Router} from "@angular/router";
import {returnOnboardingScreenImpressionName} from "../../utils/common-functions";

declare const amplitude: any;

@Injectable({
  providedIn: 'root'
})
export class AmplitudeService {

  constructor(
    public gettingStartedService: GettingStartedService,
    private router: Router,
  ) { }

  /**
   * Initialize AMPLITUDE Event tracking with API key
   */
  initialize(userId?: string){
    amplitude.getInstance().init(environment.amplitude_api_key, userId);
  }

  /**
   * Initially sets the user id after login to the amplitude instance.
   * If user is logged out, the user id is set to null.
   *
   * @param userId User Email
   * @param minoanDbId ID of current user in the Minoan Database
   * @param userType Type of current user from Join-us flow
   * @param referral Whether current user came via referral
   * @param firstLoginDate First login date of Current user
   */
  setUserId(userId: string | null, minoanDbId: number = -1, userType?: string, referral?: string, firstLoginDate?: string) {
    if (!userId) {
      amplitude.getInstance().regenerateDeviceId()
    } else {
      amplitude.getInstance().setUserId(userId.toLowerCase())
      this.setUserProperties(minoanDbId, userType, referral, firstLoginDate)
    }
  }

  /**
   * Set user properties of amplitude instance
   * @param minoanDbId ID of current user in the Minoan Database
   * @param userType Type of current user from Join-us flow
   * @param referral Whether current user came via referral
   * @param firstLoginDate First login date of Current user
   */
  setUserProperties(minoanDbId: number, userType?: string, referral?: string, firstLoginDate?: string) {
    const minoanUserId = new amplitude.Identify().set('minoan_user_id', minoanDbId);
    amplitude.getInstance().identify(minoanUserId);

    if (userType) {
      const currentUserType = new amplitude.Identify().set('user_type', userType);
      amplitude.getInstance().identify(currentUserType);
    }

    if (referral) {
      const referralType = new amplitude.Identify().set('referral', referral);
      amplitude.getInstance().identify(referralType);
    }

    if (firstLoginDate) {
      const firstLoginDateValue = new amplitude.Identify().set('first_login_date', firstLoginDate.split(' ')[0]);
      amplitude.getInstance().identify(firstLoginDateValue);
    }
  }


  /**
   * Track event in Amplitude
   * @param name Event name
   * @param eventProps Event properties
   */
  trackEvent(name: string, eventProps: any){
    amplitude.getInstance().logEvent(name, eventProps);
  }

  // addReferralUrlInSession(url:any){
  //   sessionStorage.setItem('referrerUrl' , url);
  // }
  //
  // addCurrentUrlInSession(url:any){
  //   sessionStorage.setItem('currentUrl' , url);
  // }

  /**
   * Track button click events
   * @param eventProps Event properties
   */
  buttonClickEvent(eventProps:any){
    amplitude.getInstance().logEvent('button_click', eventProps);
  }

  /**
   * To log add item event
   * @param success Event was successfull or not
   * @param requestBody Request body
   * @param addedTo Where the item is being added
   * @param userInfo Current user information
   * @param errorMessage Error message if unsuccessful
   */
  addItemEvent(success: boolean, requestBody: any, addedTo: 'quote' | 'cart' | 'wishlist' | 'new-quote' | 'mobile-navbar', userInfo: any, errorMessage: any = '') { //TODO: Remove new-quote
    const eventType = 'add_item_upload_click';

    addedTo = addedTo == 'new-quote' ? 'quote': addedTo

    const eventProps = {
      pre_populated_product_name: requestBody.prePopulatedName,
      pre_populated_variant: requestBody.prePopulatedVariant,
      pre_populated_image_count: requestBody.prePopulatedImageCount,
      product_name: requestBody.productName,
      variant: requestBody.variant,
      quantity: requestBody.quantity,
      url: requestBody.url,
      website_brand_name: requestBody.brandName,
      image_selected: requestBody.imgSelected,
      order_id: requestBody.order_id ? requestBody.order_id : undefined,
      added_to: addedTo,
      user_id: userInfo.account.id,
      account_id: userInfo.account.activeCompanyId,
      success,
      error_message: success ? undefined : errorMessage
    }
    if(success) {
      this.setAdderValue()
    }
    this.trackEvent(eventType, eventProps)
  }

  /**
   * Tracks edit item events
   * @param success Event was successful or not
   * @param requestBody Request body containing item details
   * @param addedTo Where the item is added - 'quote', 'cart' or 'wishlist'
   * @param userInfo Current user information
   * @param errorMessage Error message if unsuccessful
   * Sends amplitude event with details:
   * - previous_product_name - Previous product name before edit
   * - previous_variant - Previous variant before edit
   * - pre_populated_image_count - Prepopulated image count
   * - product_name - Updated product name
   * - variant - Updated variant
   * - quantity - Updated quantity
   * - image_selected - Images selected count
   * - added_to - Where item is added
   * - user_id - Logged in user id
   * - account_id - Company id
   * - success - Edit was successful or not
   * - error_message - Error message if unsuccessful
   */
  editItemEvent(success: boolean, requestBody: any, addedTo: 'quote' | 'cart' | 'wishlist', userInfo: any, errorMessage: any = '') {
    const eventType = 'add_item_edit_save_click';
    const eventProps = {
      previous_product_name: requestBody.prePopulatedName,
      previous_variant: requestBody.prePopulatedVariant,
      previous_quantity: requestBody.prePopulatedQuantity,
      product_name: requestBody.productName,
      variant: requestBody.variant,
      quantity: requestBody.quantity,
      image_selected: requestBody.imgSelected,
      added_to: addedTo,
      user_id: userInfo.account.id,
      account_id: userInfo.account.activeCompanyId,
      success,
      error_message: success ? undefined : errorMessage
    }
    this.trackEvent(eventType, eventProps)
  }



  /**
   * Screen Impression Event Tracker
   * @param screenName Screen name
   * @param userData User Data
   * @param orderStatus Order status if screen name is quote_detail/order_detail
   * @param orderId Order ID if screen name is quote_detail/order_detail
   * @param sendEvent To prevent sending multiple events
   */
  screenImpressionEvent(screenName: string, userData: User, orderStatus?: string, orderId?: number, sendEvent?: boolean) {
    const detailsPage = ['quote_detail', 'order_detail']

    // Current page is quote/order detail
    const isDetailsPage = detailsPage.includes(screenName)

    const eventName = 'screen_impression'

    const onboardingEvents = ['onboarding_how_it_works_welcome', 'onboarding_how_it_works_explore', 'onboarding_how_it_works_add', 'onboarding_how_it_works_quote', 'onboarding_two_ways_transition', 'onboarding_add_items_extension', 'onboarding_add_items_manual', 'onboarding_finish',]
    if(onboardingEvents.includes(screenName)) {
      screenName = returnOnboardingScreenImpressionName(screenName);
    }

    const eventObject = {
      screen_name: screenName,
      user_id: userData?.account.id,
      account_id: userData?.account.activeCompanyId,
      order_status: isDetailsPage? orderStatus : undefined,
      order_id: isDetailsPage? orderId : undefined,
    }


    if(isDetailsPage) {
      if(sendEvent)  {
        // Track event
        this.trackEvent(eventName, eventObject);
      }
    } else {
      // Track event
      this.trackEvent(eventName, eventObject);
    }
  }


  /**
   * Item move event
   * @param moveFrom Move From
   * @param moveTo Moved To
   * @param userInfo User info
   * @param itemsMoved No. of items
   * @param orderId Order id
   * @param orderIdDestination Destination order id
   */
  moveToEvent(moveFrom: 'quote' | 'wishlist' | 'cart', moveTo: 'quote' | 'wishlist' | 'cart', userInfo: User, itemsMoved: number = 1, orderId?: number, orderIdDestination?: number ) {
    const eventName = 'move_to_click'
    const eventObject = {
      move_from: moveFrom,
      move_to: moveTo,
      items_moved: itemsMoved,
      order_id: moveFrom === 'quote' ? orderId : null,
      user_id: userInfo.account.id,
      account_id: userInfo.account.activeCompanyId,
      order_id_destination: moveTo == 'quote' ? orderIdDestination : null,
    }

    this.trackEvent(eventName, eventObject);
  }

  /**
   * To track item delete success
   * @param deleteFrom Deleted From
   * @param userInfo User info
   * @param itemsDeleted No. of items
   * @param order_id Order id if available
   */
  itemDeleteEvent(deleteFrom: 'quote' | 'wishlist' | 'cart', userInfo: User, itemsDeleted: number = 1,order_id = 0) {
    const eventName = 'item_delete_click'
    const eventObject = {
      deleted_from: deleteFrom,
      items_deleted: itemsDeleted,
      user_id: userInfo.account.id,
      account_id: userInfo.account.activeCompanyId,
      order_id: order_id !== 0 ? order_id : undefined,
    }
    this.trackEvent(eventName, eventObject);
  }

  /**
   * Help center click event
   * @param location Location of event
   * @param userInfo User info
   */
  helpCenterClickEvent(location: 'quotes' | 'cart' | 'orders' | 'account', userInfo: User) {
    const eventName = 'help_center_click';
    const eventObject = {
      user_id: userInfo.account.id,
      account_id: userInfo.account.activeCompanyId,
      help_location: location,
    }
    this.trackEvent(eventName, eventObject)
  }

  /**
   * Request a quote start click event tracker.
   * User clicks "Request a Quote" on the right side card in the shopping cart OR "Request Quote"
   * from the Draft Quote OR "Request Quote Update" from an expired quote.
   * This prompts the right side tray to open with shipping and property details
   * @param location Location
   * @param itemCount Item Count
   * @param userInfo User info
   */
  requestQuoteStartClick(location: string, itemCount: number, userInfo: User){
    const requestLocationMap: {[p: string]: string} = {
      'cart': 'cart',
      'draft': 'quote_draft',
      'draft_quote': 'quote_draft',
      'quote_outdated': 'quote_expired',
    }

    if(requestLocationMap[location]) {
      const eventName = 'request_a_quote_start_click'
      const eventObject = {
        request_location: requestLocationMap[location],
        user_id: userInfo.account.id,
        account_id: userInfo.account.activeCompanyId,
        item_count: itemCount,
      }
      this.trackEvent(eventName, eventObject)
    }

  }

  /**
   * Request a quote confirmation click event tracker.
   * User clicks "Request a Quote" OR "Request Quote" OR "Request Quote Update" in the right side tray.
   * This prompts the confirmation modal that a quote request has been sent
   * @param location Location
   * @param itemCount Item Count
   * @param userInfo User info
   * @param brandCount Brand Count
   * @param requestBody Request Body
   * @param success Success
   * @param errorMessage Error Message
   */
  requestQuoteUploadClick(location: string, itemCount: number, userInfo: User, brandCount: number, requestBody: any, success: boolean, errorMessage?: any) {
    const requestLocationMap: {[p: string]: string} = {
      'cart': 'cart',
      'draft': 'quote_draft',
      'draft_quote': 'quote_draft',
      'quote_outdated': 'quote_expired',
    }

    if(requestLocationMap[location]) {
      const eventName = 'request_a_quote_upload_click'
      const eventObject = {
        request_location: requestLocationMap[location],
        user_id: userInfo.account.id,
        account_id: userInfo.account.activeCompanyId,
        item_count: itemCount,
        brand_count: brandCount,
        shipping_type: requestBody.shippingType,
        expedited_date: requestBody.expeditedDate,
        delivery_requests_notes: requestBody.specialShippingNote,
        property_selected: requestBody.properties.map((each: any) => each.propertyId),
        order_id: requestBody.quoteId ? requestBody.quoteId : undefined,
        success: success,
        error_message: success ? undefined : errorMessage,
      }
      this.setQuoterValue();
      this.trackEvent(eventName, eventObject)
    }
  }

  /**
   * Quote Sidebar address section next click
   * @param userInfo User Info
   */
  quoteAddressNextClick(userInfo: User) {
    const eventName = 'quote_address_next_click'
    const eventObject = {
      user_id: userInfo?.account.id,
      account_id: userInfo?.account.activeCompanyId,
    }
    this.trackEvent(eventName, eventObject)
  }

  /**
   * Quote Sidebar delivery section next click
   * @param userInfo User Info
   */
  quoteDeliveryNextClick(userInfo: User) {
    const eventName = 'quote_delivery_next_click'
    const eventObject = {
      user_id: userInfo?.account.id,
      account_id: userInfo?.account.activeCompanyId,
    }
    this.trackEvent(eventName, eventObject)
  }

  /**
   * Quote Sidebar details section next click
   * @param userInfo User Info
   */
  quoteDetailsNextClick(userInfo: User) {
    const eventName = 'quote_details_next_click'
    const eventObject = {
      user_id: userInfo?.account.id,
      account_id: userInfo?.account.activeCompanyId,
    }
    this.trackEvent(eventName, eventObject)
  }

  /**
   * Mark current user as quoter
   */
  setQuoterValue() {
    const quoter = new amplitude.Identify().set('quoter', 1);
    amplitude.getInstance().identify(quoter);
  }

  /**
   * Mark current user as adder
   */
  setAdderValue(){
    const adder = new amplitude.Identify().set('adder', 1);
    amplitude.getInstance().identify(adder);
  }

  /**
   * Tracks checkout button click event
   * @param orderId Current order ID
   * @param itemCount Count of Itenms
   * @param userInfo The id of the logged-in user
   */
  checkoutClickEvent(orderId: number, itemCount: number, userInfo: User) {
    const eventName= "checkout_start_click";
    const eventObject = {
      order_id: orderId,
      item_count: itemCount,
      user_id: userInfo.account.id,
      account_id: userInfo.account.activeCompanyId,
    }
    this.trackEvent(eventName, eventObject);
  }


  /**
   * Tracks filter click events for quotes/orders list page
   * @param filterType The type of filter clicked - 'status', 'brand', 'property' or 'user'
   * @param filterObj Filter Object
   * @param brandList List of all brands
   * @param propertyList List of all properties
   * @param userList List of all users
   * @param userInfo Current logged-in user information
   * @param pageType The type of page - 'quote' or 'order'
   *
   * Sends an amplitude event with details:
   * - filter_type - The filter type clicked
   * - filter_selected - The ids of filters selected based on filterType
   * - For status, the list of statuses selected
   * - For brand, the list of brand names selected
   * - For property, the list of property ids selected
   * - For user, the list of user ids selected
   * - user_id - The id of the logged-in user
   * - account_id - The id of the active company
   */
  quotesOrdersFilterClickAmplitudeEvent(filterType: 'status' | 'brand' | 'property' | 'user', filterObj: any, brandList: any[], propertyList: any[], userList: any[], userInfo: User, pageType: 'quote' | 'order') {
    const eventName = pageType === 'quote' ? 'quotes_filter_click' : 'orders_filter_click'
    const filterSelectedArray = (filter: 'status' | 'brand' | 'property' | 'user' ) => {
      switch (filter){
        case "status": return filterObj.quoteStatus;
        case "brand": return brandList.filter(each => filterObj.brandList.includes(each.brandId)).map(each => each.brandName);
        case "property": return propertyList.filter(each => filterObj.propertyList.includes(each.propertyId)).map(each => each.propertyId);
        case "user": return userList.filter(each => filterObj.usersList.includes(each.id)).map(each => each.id);
        default: return [];
      }
    }

    const eventObject = {
      filter_type: filterType,
      filter_selected: filterSelectedArray(filterType),
      user_id: userInfo.account.id,
      account_id: userInfo.account.activeCompanyId,
    }
    this.trackEvent(eventName, eventObject);
  }

  /**
   * Tracks filter click events for quotes/orders list page
   * @param searchKeyword Search keyword
   * @param userInfo Current logged-in user information
   * @param pageType The type of page - 'quote' or 'order'
   *
   * Sends an amplitude event with details:
   * - searched_value - Search keyword
   * - user_id - The id of the logged-in user
   * - account_id - The id of the active company
   */
  quoteOrderSearchEvent(searchKeyword: string, userInfo: User, pageType: 'quote' | 'order') {
    const eventObject = {
      searched_value: searchKeyword,
      user_id: userInfo.account.id,
      account_id: userInfo.account.activeCompanyId,
    }
    const eventName = pageType === 'quote' ? 'quotes_search_action' : 'orders_search_action'
    this.trackEvent(eventName, eventObject);
  }

  /**
   * Tracks Item Quantity Change on quote, cart and wishlist pages
   * @param requestBody Product information
   * @param addedTo Added to string - 'cart', 'quote' or 'wishlist
   * @param userInfo User details
   * @param orderId Order ID
   *
   * Sends an amplitude event with details:
   * - added_to - Where the item quantity was updated - 'cart', 'quote' or 'wishlist'
   * - order_id - The ids of filters selected based on filterType
   * - user_id - The id of the logged-in user
   * - account_id - The id of the active company
   */
  itemQuantityClicKEvent(requestBody: any, addedTo: 'cart' | 'quote' | 'wishlist', userInfo: User, orderId?: number) {
    const eventName = 'item_quantity_click'
    const eventObject = {
      added_to: addedTo,
      product_name: requestBody.productName,
      user_id: userInfo.account.id,
      account_id: userInfo.account.activeCompanyId,
      order_id: addedTo === 'quote' ? orderId : undefined,
    }
    this.trackEvent(eventName, eventObject)
  }

  /**
   * Tracks Extension download link click
   * @param location Location where link was clicked
   * @param userInfo User details
   *
   * Sends and amplitude event with details
   * - user_id - The id of the logged-in user
   * - account_id - The id of the active company
   * - location - Location where the click event took place
   */
  extensionDownloadClickEvent(location: string, userInfo: User) {
    const eventName = 'download_extension_click'
    const eventObject = {
      user_id: userInfo.account.id,
      account_id: userInfo.account.activeCompanyId,
      location: location
    }

    this.trackEvent(eventName, eventObject)
  }


  /**
   * Tracks clicks on shipping and delivery article link
   * @param location Location where article link was clicked
   * @param userInfo User details
   *
   * Send an amplitude event with details:
   *  - user_id -This id of the logged-in user
   *  - account_id -This id of the active company
   *  - location - Location where article was clicked
   */
  shippingAndDeliveryArticleClickEvent(location: 'order_detail' | 'request_a_quote_panel' ,userInfo: User) {
    const eventName = 'shipping_and_delivery_article_click'
    const eventObject = {
      user_id: userInfo.account.id,
      account_id: userInfo.account.activeCompanyId,
      location,
    }
    this.trackEvent(eventName, eventObject)
  }

  /**
   * Tracks which step is expanded in ftux card
   * User clicks on a step in the Stepper to expand the Step
   * @param stepIndex New Step index
   * @param currentActiveStepIndex Old Step index
   * @param userInfo User Details
   */
  ftuxCardStepExpandClick(stepIndex: number, currentActiveStepIndex: number, userInfo: User) {
    if(currentActiveStepIndex === -1 && this.gettingStartedService.showFtuxTemporarily) {
      currentActiveStepIndex = 4
    }
    const GETTING_STARTED_CONTENT = this.gettingStartedService.GETTING_STARTED_CONTENT
    const [step_name, current_active_step] = [GETTING_STARTED_CONTENT[stepIndex].amplitudeTitle, GETTING_STARTED_CONTENT[currentActiveStepIndex].amplitudeTitle]

    const eventName = 'ftux_card_step_expand_click'
    const eventObject = {
      step_name,
      current_active_step,
      user_id: userInfo.account.id,
      account_id: userInfo.account.activeCompanyId,
    }

    this.trackEvent(eventName, eventObject)
  }

  /**
   * Tracks which step's cta is clicked
   * User clicks on a CTA in a Step
   * @param stepIndex Currently opened step index
   * @param currentActiveStepIndex Last active step index
   * @param userInfo User Details
   */
  ftuxCardCtaClick(stepIndex: number, currentActiveStepIndex: number, userInfo: User) {
    if(currentActiveStepIndex === -1 && this.gettingStartedService.showFtuxTemporarily) {
      currentActiveStepIndex = 4
    }
    const GETTING_STARTED_CONTENT = this.gettingStartedService.GETTING_STARTED_CONTENT
    const [step_name, current_active_step] = [GETTING_STARTED_CONTENT[stepIndex].amplitudeTitle, GETTING_STARTED_CONTENT[currentActiveStepIndex].amplitudeTitle]
    const cta_name = stepIndex === 0 ? 'install_extension' : 'show_me_how'

    const eventName = 'ftux_card_step_CTA_click'
    const eventObject = {
      step_name,
      cta_name,
      current_active_step,
      user_id: userInfo.account.id,
      account_id: userInfo.account.activeCompanyId,
    }

    this.trackEvent(eventName, eventObject)
  }

  /**
   * Tracks closing of FTUX card
   * User clicks the "X" or presses esc to close the open FTUX card
   * @param currentActiveStepIndex Last active step index
   * @param userInfo User Details
   */
  ftuxCardCloseClick(currentActiveStepIndex: number, userInfo: User) {
    if(currentActiveStepIndex === -1 && this.gettingStartedService.showFtuxTemporarily) {
      currentActiveStepIndex = 4
    }
    const GETTING_STARTED_CONTENT = this.gettingStartedService.GETTING_STARTED_CONTENT
    const current_active_step = GETTING_STARTED_CONTENT[currentActiveStepIndex].amplitudeTitle

    const eventName = 'ftux_card_close_click'
    const eventObject = {
      current_active_step,
      user_id: userInfo.account.id,
      account_id: userInfo.account.activeCompanyId,
    }

    this.trackEvent(eventName, eventObject)
  }

  /**
   * Tracks opening of FTUX card
   * User clicks the closed FTUX tab to open the FTUX card
   * @param currentActiveStepIndex Last active step index
   * @param userInfo User Details
   */
  ftuxCardOpenClick(currentActiveStepIndex: number, userInfo: User) {
    if(currentActiveStepIndex === -1 && this.gettingStartedService.showFtuxTemporarily) {
      currentActiveStepIndex = 4
    }
    const GETTING_STARTED_CONTENT = this.gettingStartedService.GETTING_STARTED_CONTENT
    const current_active_step = GETTING_STARTED_CONTENT[currentActiveStepIndex].amplitudeTitle
    let state = 0;
    const currentState = this.router.routerState.snapshot.url
    if(currentState.includes('/shop-and-browse')) {
      state = 0
    } else if (currentState.includes('/quotes/detail')) {
      state = 2
    } else if (currentState.includes('/quotes')) {
      state = 1
    } else if (currentState.includes('/orders/detail')) {
      state = 4
    } else if (currentState.includes('/orders/detail')) {
      state = 3
    } else if (currentState.includes('/wishlist')) {
      state = 5
    } else if (currentState.includes('/management/account')) {
      state = 6
    } else if (currentState.includes('/management/properties')) {
      state = 7
    } else if (currentState.includes('/management/users')) {
      state = 8
    } else if (currentState.includes('/management/addresses')) {
      state = 9
    } else if (currentState.includes('/referral')) {
      state = 10
    } else if (currentState.includes('/cart')) {
      state = 11
    }
    const screenNameMap = ['shop_and_browse', 'quotes', 'quote_detail', 'orders', 'order_detail', 'wishlist', 'account', 'properties', 'users', 'shipping_addresses', 'referral', 'cart',];

    if(screenNameMap[state]) {
      const eventName = 'ftux_card_open_click'
      const eventObject = {
        screen_name: screenNameMap[state],
        current_active_step,
        user_id: userInfo.account.id,
        account_id: userInfo.account.activeCompanyId,
      }

      this.trackEvent(eventName, eventObject)
    }
  }

  /**
   * Tracks User clicks on an item in the Help Section of the FTUX card
   * @param item_name Clicked item name
   * @param userInfo User Details
   */
  ftuxCardHelpClick(item_name: "how_minoan_works" | "group_demo", userInfo: User) {
    const eventName = 'ftux_card_help_click'
    const eventObject = {
      item_name,
      user_id: userInfo.account.id,
      account_id: userInfo.account.activeCompanyId,
    }
    this.trackEvent(eventName, eventObject)
  }

  /**
   * Tracks User clicks on an item in the Setup Section of the FTUX card
   * @param item_name Clicked item name
   * @param userInfo User Details
   */
  ftuxCardSetupClick(item_name: "add_properties" | "invite_team", userInfo: User) {
    const eventName = 'ftux_card_setup_click'
    const eventObject = {
      item_name,
      user_id: userInfo.account.id,
      account_id: userInfo.account.activeCompanyId,
    }
    this.trackEvent(eventName, eventObject)
  }


}
