import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {AuthService} from "../auth/auth.service";
import {PropertySelectionService} from "../base/property-selection.service";
import {StatsService} from "../stats/stats.service";
import {FlagsmithService} from "../flagsmith/flagsmith.service";
import {EncryptionService} from "../encryption/encryption.service";
import {LOCAL_STORAGE_INFO_VARIABLE, TEMPORARY_ONBOARDING_SESSION_VARIABLE} from "../../constants/constants";
import {firstValueFrom} from "rxjs";
import {AmplitudeService} from "../events/amplitude.service";
import {IntercomService} from "../misc/intercom.service";
import {RandomService} from "../misc/random.service";

@Injectable({
  providedIn: 'root'
})
export class AppInitService {
  constructor(
    public auth: AuthService,
    private selectedPropertyService: PropertySelectionService,
    private statsService: StatsService,
    public intercom: IntercomService,
    private flagsmithService: FlagsmithService,
    private encryptionService: EncryptionService,
    public amplitudeService: AmplitudeService,
    public randomService: RandomService,
    ) { }

  async init() {
    this.amplitudeService.initialize();
    if (environment.env === 'stage' || environment.env === 'prod'){
      this.randomService.initDataDogRum();

      // Inject script in case of prod
      if (environment.env === 'prod'){
        this.randomService.injectKlaviyoScript(`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${environment.klaviyoPublicKey}`).then(() => {
          console.log('Klaviyo script loaded successfully');
        }).catch((error) => {
          console.error('Error loading Klaviyo script:', error);
        });
      }
    }

    if(this.auth.isAuthenticated){
      await firstValueFrom(this.auth.checkAccessToken()).then((response: any) => {
        this.statsService.updateGlobalStats();
        const encryptedData = this.encryptionService.encryptData(response.data)
        const accountData = response.data.account;
        if (environment.env === 'stage' || environment.env === 'prod'){
          this.randomService.setDataDogRumUser(accountData?.id,accountData?.email);
        }
        const [email, minoanDbId, userType, referralCode, firstLoginDate] = [accountData.email, accountData.id, accountData.amplitudeEventData.userType, accountData.amplitudeEventData.referralCode, accountData.amplitudeEventData.firstLoginDate]
        this.amplitudeService.setUserId(email, minoanDbId, userType, referralCode, firstLoginDate);

        localStorage.setItem(LOCAL_STORAGE_INFO_VARIABLE, encryptedData);
        this.auth.user.next(response.data);
        this.flagsmithService.initFlagsmith()
        const userData = response.data
        const isTemporaryOnboarding = !!sessionStorage.getItem(TEMPORARY_ONBOARDING_SESSION_VARIABLE)
        if(environment.intercom && !userData.showOnboarding && !isTemporaryOnboarding){
          this.intercom.boot({
            app_id: 'tctf3las',
            name: userData.account.name,
            email: userData.account.email,
            created_at: userData.account.createdAt,
            firstTimeUser: false,
            widget: {
              activator: '#intercom'
            },
            hide_default_launcher: isTemporaryOnboarding
          });
        }

        const selectedCompany = response.data.permissions.find((eachCompany: any) => eachCompany.companyId == response.data.account.activeCompanyId)
        this.selectedPropertyService.setAllData(selectedCompany, response.data.permissions)
      }).catch((err) => {
        localStorage.removeItem(LOCAL_STORAGE_INFO_VARIABLE);
        this.intercom.shutdown();
      })
    }
  }
}
