import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {RandomService} from "../services/misc/random.service";
import {GettingStartedService} from "../services/misc/getting-started.service";
import {AuthService} from "../services/auth/auth.service";
import {AmplitudeService} from "../services/events/amplitude.service";

@Component({
  selector: 'app-getting-started',
  templateUrl: './getting-started.component.html',
  styleUrls: ['./getting-started.component.scss']
})
export class GettingStartedComponent implements OnChanges {

  ftuxArr: boolean[] = this.gettingStartedService.currentFTUXStatus
  currentActiveIndex: number[] = [this.gettingStartedService.currentActiveIndex];

  @Input() gettingStartedMenu: boolean = false;
  @Output() gettingStartedMenuChange = new EventEmitter<boolean>()

  constructor(
    public randomService: RandomService,
    public gettingStartedService: GettingStartedService,
    public auth: AuthService,
    public amplitudeService: AmplitudeService,
  ) {
    this.gettingStartedService.ftuxStatus.subscribe({
      next: (value) => {
        this.ftuxArr = value
        this.currentActiveIndex = [this.gettingStartedService.currentActiveIndex]
      }
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['gettingStartedMenu']) {
      this.ftuxArr = this.gettingStartedService.currentFTUXStatus;
      this.currentActiveIndex = [this.gettingStartedService.currentActiveIndex]
    }
  }


  get userInfo() {
    return this.auth.user.getValue()!
  }

  handleAccordionOpen(event: {originalEvent: any, index: number}) {
    this.amplitudeService.ftuxCardStepExpandClick(event.index, this.gettingStartedService.currentActiveIndex, this.userInfo)
  }

  /**
   * Event handler for which step's CTA was clicked
   * @param index Step index
   */
  ftuxStepCtaClick(index: number) {
    this.amplitudeService.ftuxCardCtaClick(index, this.gettingStartedService.currentActiveIndex, this.userInfo)
  }

  /**
   * Handle close mobile ftux card
   */
  gettingBack(){
    this.amplitudeService.ftuxCardCloseClick(this.gettingStartedService.currentActiveIndex, this.userInfo)
    document.body.classList.remove('overflow');
    this.gettingStartedMenuChange.emit(false)
  }

}
