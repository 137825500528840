import {Injectable} from '@angular/core';

interface ToastInfo {
  data?: any
  severity: string
  detail: string
  styleClass: string
  life?: number
}

// Default toast life
const toastLife: number = 5000

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor() { }

  toasts: ToastInfo[] = []

  /**
   * To show success toast
   * @param message Toast message
   * @param body Toast customizer
   */
  showSuccessToast(message:string, body: any = undefined) {
    this.toasts.push({severity:'success', detail: message, styleClass: 'cstm_toast_success', life: toastLife,   data: body})
  }

  /**
   * Callback method to remove Toast DOM element from view
   * @param toast Toast to remove from DOM
   */
  remove(toast: ToastInfo) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  showInfoToast(message:string) {
    this.toasts.push({severity:'info', detail: message, styleClass: 'cstm_toast', life: toastLife})

  }

  showWarningToast(message:string) {
    this.toasts.push({severity:'warn', detail: message, styleClass: 'cstm_toast', life: toastLife})

  }

  /**
   * To show error toast
   * @param message Toast message
   */
  showErrorToast(message:string) {
    this.toasts.push({severity:'error', detail: message, styleClass: 'cstm_toast_error', life: toastLife })
  }
}
