<div class="">
  <div class="product_slide" >
    <h4>Selected Image</h4>
    <div class="main_image" [ngClass]="{'more_than_one_img': images.length > 1 && !imagesWrongChecked }">
      <img id="mainImage" src="{{imagesWrongChecked ? PLACEHOLDER_URL : images[mainImageIndex]}}" alt="img" />
      <span *ngIf="!imagesWrongChecked && images.length > 1" class="check_icon">
       <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#12B76A"/>
        <path d="M16.4286 9.64282L9.75007 16.3214L6.71436 13.2857" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

      </span>
      <div class="slide_btn">
        <button class="btn prev_btn" id="prevProductImageBtn" type="button" *ngIf="images.length > 1 && !imagesWrongChecked" (click)="prevImage()">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">-->
            <path d="M12.5 15L7.5 10L12.5 5" stroke="#DC5A4B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
        <button class="btn next_btn" id="nextProductImageBtn" type="button" *ngIf="images.length > 1 && !imagesWrongChecked" (click)="nextImage()">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">-->
            <path d="M7.5 15L12.5 10L7.5 5" stroke="#DC5A4B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>
    </div>

    <div class="thumbnail_list" *ngIf="images.length > 1 && !showSkeleton" >
      <ng-container *ngFor="let image of images; let i = index">
        <div class="thumbnail_card desk_hover_only" [ngClass]="{'images_disabled': imagesWrongChecked}" id="productImage-{{i}}" (click)="imageClicked(i)">
          <img src="{{image}}" alt="img"/>
        </div>
      </ng-container>
    </div>

    <div class="thumbnail_list" *ngIf="showSkeleton">
      <ng-container *ngFor="let _ of [].constructor(6)">
        <div class="thumbnail_card desk_hover_only images_disabled">
          <p-skeleton  size="2.5rem"></p-skeleton>
        </div>
      </ng-container>
    </div>

    <div class="form-group"  *ngIf="showNoneOfTheseCheckbox">
      <div class="fg-inner">
        <div class="custom-checkbox-group">
          <p-checkbox [binary]="true" inputId="noImages-desktop" styleClass="custom-checkbox-button" [(ngModel)]="imagesWrongChecked" (onChange)="imagesWrongCheckedEventHandler($event)"></p-checkbox>
          <label class="checkbox-button-label" for="noImages-desktop">None of these show my item</label>
        </div>
      </div>
    </div>
  </div>
  <div class="mobile_carousel_selector" [ngClass]="{'no_images_mob': !showNoneOfTheseCheckbox}">
    <button class="btn btn_outline" (click)="mobileCarousel = true">
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
          <path d="M12.15 15.75H5.19853C4.74417 15.75 4.51699 15.75 4.41179 15.6602C4.32051 15.5822 4.27207 15.4652 4.28149 15.3456C4.29234 15.2077 4.45298 15.047 4.77426 14.7257L11.1515 8.34853C11.4485 8.05152 11.597 7.90301 11.7682 7.84737C11.9189 7.79842 12.0811 7.79842 12.2318 7.84737C12.403 7.90301 12.5515 8.05152 12.8485 8.34853L15.75 11.25V12.15M12.15 15.75C13.4101 15.75 14.0402 15.75 14.5215 15.5048C14.9448 15.289 15.289 14.9448 15.5048 14.5215C15.75 14.0402 15.75 13.4101 15.75 12.15M12.15 15.75H5.85C4.58988 15.75 3.95982 15.75 3.47852 15.5048C3.05516 15.289 2.71095 14.9448 2.49524 14.5215C2.25 14.0402 2.25 13.4101 2.25 12.15V5.85C2.25 4.58988 2.25 3.95982 2.49524 3.47852C2.71095 3.05516 3.05516 2.71095 3.47852 2.49524C3.95982 2.25 4.58988 2.25 5.85 2.25H12.15C13.4101 2.25 14.0402 2.25 14.5215 2.49524C14.9448 2.71095 15.289 3.05516 15.5048 3.47852C15.75 3.95982 15.75 4.58988 15.75 5.85V12.15M7.875 6.375C7.875 7.20343 7.20343 7.875 6.375 7.875C5.54657 7.875 4.875 7.20343 4.875 6.375C4.875 5.54657 5.54657 4.875 6.375 4.875C7.20343 4.875 7.875 5.54657 7.875 6.375Z" stroke="#444444" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </span>
      Edit Image</button>
    <div class="mobile_image">
      <img src="{{imagesWrongChecked ? PLACEHOLDER_URL : images[mainImageIndex]}}">
    </div>
  </div>
</div>


<p-dialog [visible]="mobileCarousel" [showHeader]="false" [modal]="true" [baseZIndex]="10000"
          [draggable]="false" [resizable]="false" styleClass="cart_modal "
          maskStyleClass="mask-class-2 padding0">
  <div class="modal-content">
    <div class="modal-header">
      <div class="heading_wrap">
        <h2 class="heading3">Select Image</h2>
      </div>
      <button (click)="mobileCarousel = false" type="button" class="close-btn">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.5 1.5L1.5 16.5M1.5 1.5L16.5 16.5" stroke="#96999F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>
    <div class="modal_body">
      <div class="mobile_wrap">
        <div class="mobile_thumbnail_wrap">
          <ng-container *ngFor="let image of images; let i = index">
            <div class="thumbnail_card" [ngClass]="{'image_checked': mainImageIndex == i && !imagesWrongChecked}" id="productImage-{{i}}" (click)="imageClicked(i)">
              <img src="{{image}}" alt="img"/>
              <span class="check_icon" *ngIf="mainImageIndex == i && !imagesWrongChecked">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill="#12B76A"/>
            <path d="M16.4286 9.64282L9.75007 16.3214L6.71436 13.2857" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="modal-footer align-self-center p_sticky_mob">
      <div class="form-group center-ui">
        <div class="fg-inner">
          <div class="custom-checkbox-group">
            <p-checkbox [binary]="true" inputId="noImages-mobile" styleClass="custom-checkbox-button" [(ngModel)]="imagesWrongChecked" (onChange)="imagesWrongCheckedEventHandler($event)"></p-checkbox>
            <label class="checkbox-button-label" for="noImages-mobile">None of these show my item</label>
          </div>
        </div>
      </div>
      <button type="button" class="btn btn_primary" id="imageSelectDone" (click)="mobileCarousel = false">
        Done
      </button>
    </div>
  </div>
</p-dialog>
