export const environment = {
  production: true,
  apiUrl: 'https://stageb2b-api.minoan.com',
  shoppableSiteBase: 'visitor-dev.minoanexperience.com',
  amplitude_api_key: 'd298b6489c6c52dd337b0241d3d62d78',
  GET_BALANCE_URL: 'https://checkout-v2.sandbox.getbalance.com/sdk-latest.js',
  flagSmithApiKey: 'bdzBjjeYznPCMzWV3uy28R',
  intercom: true,
  wSBrands: [32, 37, 84, 93, 492, 265, 91],
  encryptionKey : 'GZH2K4M5N6',
  dataDogApplicationId:'282a3108-f4dc-4d4e-a556-b3a158b1cd4e',
  dataDogClientToken:'pubd1a33588cf4c8080651a6b13f840371e',
  env: 'stage',
  klaviyoPublicKey: 'XeFT7f',
};
