// Regex for all emails
export declare class Options {
  bounds: any;
  componentRestrictions: { country: string };
  types: string[];
  fields: string[];
  strictBounds: boolean;
  origin?: any;
  constructor(opt?: Partial<Options>);
}


const EMAIL_REGEX = /^\S+@\S+\.\S+$/;
const PHONE_REGEX = /\(\d\d\d\) \d\d\d-\d\d\d\d/;
const URL_REGEX = /^\S+\.\S+$/;

const STATES = [
  {
    name: 'Alabama',
    abbreviation: 'AL'
  },
  {
    name: 'Alaska',
    abbreviation: 'AK'
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ'
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR'
  },
  {
    name: 'California',
    abbreviation: 'CA'
  },
  {
    name: 'Colorado',
    abbreviation: 'CO'
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT'
  },
  {
    name: 'Delaware',
    abbreviation: 'DE'
  },
  {
    name: 'District of Columbia',
    abbreviation: 'DC'
  },
  {
    name: 'Florida',
    abbreviation: 'FL'
  },
  {
    name: 'Georgia',
    abbreviation: 'GA'
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI'
  },
  {
    name: 'Idaho',
    abbreviation: 'ID'
  },
  {
    name: 'Illinois',
    abbreviation: 'IL'
  },
  {
    name: 'Indiana',
    abbreviation: 'IN'
  },
  {
    name: 'Iowa',
    abbreviation: 'IA'
  },
  {
    name: 'Kansas',
    abbreviation: 'KS'
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY'
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA'
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE'
  },
  {
    name: 'Nevada',
    abbreviation: 'NV'
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH'
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ'
  },
  {
    name: 'New York',
    abbreviation: 'NY'
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC'
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND'
  },
  {
    name: 'Ohio',
    abbreviation: 'OH'
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK'
  },
  {
    name: 'Oregon',
    abbreviation: 'OR'
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA'
  },
  {
    name: 'Puerto Rico',
    abbreviation: 'PR'
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI'
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC'
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD'
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN'
  },
  {
    name: 'Maine',
    abbreviation: 'ME'
  },
  {
    name: 'Texas',
    abbreviation: 'TX'
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA'
  },
  {
    name: 'Utah',
    abbreviation: 'UT'
  },
  {
    name: 'Maine',
    abbreviation: 'ME'
  },
  {
    name: 'Vermont',
    abbreviation: 'VT'
  },
  {
    name: 'Maryland',
    abbreviation: 'MD'
  },
  {
    name: 'Virginia',
    abbreviation: 'VA'
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA'
  },
  {
    name: 'Virgin Islands',
    abbreviation: 'VI'
  },
  {
    name: 'Michigan',
    abbreviation: 'MI'
  },
  {
    name: 'Washington',
    abbreviation: 'WA'
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN'
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV'
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS'
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI'
  },
  {
    name: 'Missouri',
    abbreviation: 'MO'
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY'
  },
  {
    name: 'Montana',
    abbreviation: 'MT'
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM'
  },
]


const TOAST_MESSAGES = {
  SOMETHING_WENT_WRONG: 'Something went wrong.',

  // Account Management Toasts
  ACCOUNT_DETAILS_FETCH_FAIL: 'Unable to fetch account details',
  ACCOUNT_NAME_CHANGE_SUCCESS: 'Account Name updated',
  ACCOUNT_NAME_CHANGE_FAIL: 'Unable to update Account Name',
  MY_INFO_CHANGE_SUCCESS: 'My Info updated',
  MY_INFO_CHANGE_FAIL: 'Unable to update My Info',

  // Property Management Toasts
  PROPERTY_DETAILS_FETCH_FAIL: 'Unable to fetch property list',
  NEW_PROPERTY_ADD_SUCCESS: 'New property added',
  NEW_PROPERTY_ADD_FAIL: 'Failed to add new property',
  PROPERTY_EDIT_SUCCESS: 'Property updated',
  PROPERTY_EDIT_FAIL: 'Failed to edit property',
  PROPERTY_DELETE_SUCCESS_NO_USERS: 'Property removed',
  PROPERTY_DELETE_SUCCESS_WITH_USERS: (propName: string, userCount: number) => `Property removed`,
  PROPERTY_DELETE_FAIL: 'Unable to delete property',


  // User Management Toasts
  USER_LIST_FETCH_FAIL: 'Unable to fetch user list',
  USER_INVITE_SUCCESS: (email: string) => `Invite sent`,
  USER_INVITE_FAIL: 'Failed to invite user',
  USER_RESEND_INVITE_SUCCESS: (email:string) => `Invite re-sent`,
  USER_RESEND_INVITE_FAIL: 'Failed to resend invite',
  USER_DELETE_INVITE_SUCCESS: (email:string) => `Invite deleted`,
  USER_DELETE_INVITE_FAIL: 'Failed to delete invite',
  USER_ACCESS_CHANGE_SUCCESS: (name: string) => `${name}’s access updated`,
  USER_OWNER_CHANGE_SUCCESS: (name: string) => `Owner changed to ${name} `,
  USER_UPDATE_FAIL: 'Failed to update user access',
  USER_DELETE_SUCCESS: (name: string) => `User removed`,
  USER_DELETE_FAIL: `Failed to remove user`,

  // Address Management Toasts
  ADDRESS_LIST_FETCH_FAIL: 'Unable to fetch address list',
  ADDRESS_ADD_SUCCESS: `New address added`,
  ADDRESS_ADD_FAIL: 'Failed to add new address',
  ADDRESS_EDIT_SUCCESS: `Address updated`,
  ADDRESS_EDIT_FAIL: 'Failed to update address',
  ADDRESS_DEFAULT_CHANGE_SUCCESS: 'Default address updated',
  ADDRESS_DEFAULT_CHANGE_FAIL: 'Failed to update default address',
  ADDRESS_DELETE_SUCCESS: `Address deleted`,
  ADDRESS_DELETE_FAIL: 'Failed to delete address',


  // Reset Password Toasts
  NO_RESET_KEY: 'Link is invalid. No reset key detected.',
  RESET_KEY_INVALID: 'Login link has expired. Please reset your password.',
  RESET_PASSWORD_FAILED: 'Failed to reset password',

  // Create Password Toasts
  CREATE_PASSWORD_FAILED: 'Failed to create password',

  // Account Setup Toasts
  NO_SETUP_KEY: 'Link is invalid. No setup key detected.',
  ACCOUNT_SETUP_INVITE_REVOKED: 'Your invitation to this account has expired or been deleted.',
  ACCOUNT_SETUP_FAILED: 'Your invitation to this account has expired or been deleted.',
  ACCOUNT_NOT_FOUND: 'Account is no longer active.',
  INVALID_ACCOUNT: 'You do not have access to join this account.',
  SETUP_KEY_INVALID: 'Unable to join account. Setup key is invalid.',
  ACCOUNT_JOIN_SUCCESS: (accountName: string) => `You have joined ${accountName}`,

  // HEADER TOASTS
  ACCOUNT_CHANGE_SUCCESS: (accountName: string) => `Switched to ${accountName}`,

  // CART TOASTS
  CART_FETCH_FAILED: 'Failed to fetch cart items.',
  CART_NEW_ITEM_SUCCESS: 'Item added.',
  CART_X_ITEM_SUCCESS: (itemCount: number) => `${itemCount} ${itemCount > 1 ? 'items' : 'item'} added.`,
  CART_NEW_ITEM_FAIL: 'Failed to add new item in cart.',
  CART_EDIT_SUCCESS: 'Item updated.',
  CART_EDIT_FAIL: 'Failed to edit item.',
  CART_ITEM_DELETE_SUCCESS: 'Item deleted.',
  CART_ITEM_DELETE_FAIL: 'Failed to delete cart item.',
  CART_ITEM_WISHLIST_SUCCESS: 'Item moved to wishlist.',
  CART_ITEM_WISHLIST_FAIL: 'Failed to move the item to wishlist.',
  CART_ITEM_ALL_WISHLIST_SUCCESS: (itemCount: number) => `${itemCount} ${itemCount > 1 ? 'items' : 'item'} moved to wishlist.`,
  CART_ITEM_ALL_WISHLIST_FAIL: 'Failed to move items to wishlist',
  CART_ITEM_ALL_DELETE_SUCCESS: (itemCount: number) => itemCount > 1 ? `${itemCount} items deleted.`: 'Item deleted.',
  CART_ITEM_DELETE_FAIl: 'Failed to delete items.',
  CART_ITEM_ALL_MOVE_TO_QUOTE_SUCCESS: (itemCount: number, quoteName: string) => `${itemCount} ${itemCount > 1 ? 'items' : 'item'} moved to ${quoteName}.`,
  CART_ITEM_ALL_MOVE_TO_QUOTE_FAIL: 'Failed to move items to Quote',
  CART_SUBMIT_FAIL: `Failed to request a quote from the cart`,

  WISHLIST_FETCH_FAILED: 'Failed to fetch wishlist items.',
  WISHLIST_NEW_ITEM_SUCCESS: 'Item added.',
  WISHLIST_NEW_ITEM_FAIL: 'Failed to add new item in wishlist.',
  WISHLIST_EDIT_SUCCESS: 'Item updated.',
  WISHLIST_EDIT_FAIL: 'Failed to edit item.',
  WISHLIST_ITEM_DELETE_SUCCESS: 'Item deleted.',
  WISHLIST_ITEM_DELETE_FAIL: 'Failed to delete wishlist item.',
  WISHLIST_ITEM_WISHLIST_SUCCESS: 'Item moved to wishlist.',
  WISHLIST_ITEM_WISHLIST_FAIL: 'Failed to move the item to wishlist.',
  WISHLIST_ITEM_ALL_WISHLIST_SUCCESS: (itemCount: number) => `${itemCount} ${itemCount > 1 ? 'items' : 'item'} moved to wishlist.`,
  WISHLIST_ITEM_ALL_WISHLIST_FAIL: 'Failed to move items to wishlist',
  WISHLIST_ITEM_ALL_DELETE_SUCCESS: (itemCount: number) => itemCount > 1 ? `${itemCount} items deleted.`: 'Item deleted.',
  WISHLIST_ITEM_DELETE_FAIl: 'Failed to delete items.',
  WISHLIST_ITEM_ALL_MOVE_TO_QUOTE_SUCCESS: (itemCount: number, quoteName: string) => `${itemCount} ${itemCount > 1 ? 'items' : 'item'} moved to ${quoteName}.`,
  WISHLIST_ITEM_ALL_MOVE_TO_QUOTE_FAIL: 'Failed to move items to Quote',

  // QUOTE TOASTS
  QUOTE_DETAILS_FETCH_FAILED: 'Failed to fetch quote details',
  QUOTE_LIST_FETCH_FAIL: 'Failed to fetch list of quotes.',
  QUOTE_NEW_ITEM_SUCCESS: 'Item Added.',
  QUOTE_X_ITEM_SUCCESS: (itemCount: number) => `${itemCount} ${itemCount > 1 ? 'items' : 'item'} added.`,
  QUOTE_ITEM_UPDATE:'Item updated.',
  QUOTE_ITEM_DELETE:'Item deleted.',
  QUOTE_ITEM_ALL_DELETE_SUCCESS: (itemCount: number) => itemCount > 1 ? `${itemCount} items deleted.`: 'Item deleted.',
  QUOTE_NEW_ITEM_FAIL: 'Failed to add new item in quote.',
  QUOTE_ITEM_ALL_CART_FAILED: 'Failed to move items to cart',
  QUOTE_ITEM_CART_FAILED: 'Failed to move item to cart',
  QUOTE_SUBMIT_FAIL: `Failed to request a quote from the draft`,
  QUOTE_ARCHIVE_SUCCESS: (quoteName: string) => `${quoteName.length > 30 ? `${quoteName.slice(0, 30)} ...`: quoteName} has been archived.`,
  QUOTE_ARCHIVE_FAIL: `Failed to archive quote`,
  QUOTE_NOTIFIED_USER_CHANGE_FAIL: 'Failed to change notified users',
  QUOTE_PROPERTY_CHANGE_FAIL: 'Failed to change properties',
  QUOTE_NAME_CHANGE_FAIL: 'Failed to change quote name',
  INVOICE_DOWNLOAD_SUCCESS: 'Invoice downloaded successfully.',
  RECEIPT_DOWNLOAD_SUCCESS: 'Receipt downloaded successfully.',
  INVOICE_DOWNLOAD_FAILURE: 'Failed to download invoice',
  RECEIPT_DOWNLOAD_FAILURE: 'Failed to download receipt',
  MAX_SHIPDATE_EMPTY: 'Please select a date for expedited delivery.',
  QUOTE_NOT_ACCESSABLE: 'This quote is not viewable from this account. Switch to the correct account to view this quote.',
  ORDER_NOT_ACCESSABLE: 'This order is not viewable from this account. Switch to the correct account to view this order.',

  REFERRAL_LINK_COPY_SUCCESS: 'Link copied.',
}

// To change/add countries
const GOOGLE_MAP_COUNTRY_RESTRICTION = {
  country: "us"
}

// For complete address
const GOOGLE_MAP_RESTRICTIONS = {
  componentRestrictions: GOOGLE_MAP_COUNTRY_RESTRICTION
} as Options

// For cities in country restriction
const GOOGLE_MAP_CITY_RESTRICTION = {
  types:['(cities)'],
  componentRestrictions: GOOGLE_MAP_COUNTRY_RESTRICTION
} as Options


const ALL_USER_ROLES = [
  {
    name: 'Owner',
    code: 'owner',
    info: 'Full access to place orders, manage users, change ownership, and edit all properties.',
  },
  {
    name: 'Admin',
    code: 'admin',
    info: 'Full access to place orders, manage users, and edit all properties.',
  },
  {
    name: 'Member',
    code: 'member',
    info: 'Can place orders and view information for selected properties only.',
  },
];

const INVITED_USER_ROLES = [
  {
    name: 'Admin',
    code: 'admin',
    info: 'Full access to place orders, manage users, and edit all properties.',
  },
  {
    name: 'Member',
    code: 'member',
    info: 'Can place orders and view information for selected properties only.',
  },
];

const LOADER_MESSAGES = {
  DOWNLOAD_IN_PROGRESS: 'Your download is in progress.'
}

const PLACEHOLDER_URL = '/assets/images/quotes/coming_soon.png'


const deliveryStatusKey: any = {
  '':'',
  'info_received': 'Label Created',
  'pending': 'Pending',
  'in_transit': 'In Transit',
  'out_for_delivery': 'Out For Delivery',
  'delivered': 'Delivered',
  'exception': 'Exception',
  'attempt_fail': 'Failed Attempt',
  'expired': 'Expired',
  'not_trackable': 'Not Trackable',
  'return_to_sender': 'Return To Sender',
  'registered': 'Registered',
  'available_for_pickup': 'Available For Pickup',
  'issue': 'Issue',
}

const NON_MINOAN_PARTNER_BRANDID: number = 414;

const LOCAL_STORAGE_INFO_VARIABLE: string = 'userInfo'

/**
 * Brands that are excluded from scraping API call
 * 82: Crate & Barrel
 */
const BRANDS_EXCLUDED_FROM_SCRAPING = [82]

// 1000 MS Delay option
const DELAY_1000_MS: number = 1000;

// Root bound condition for select all intersection observer
const ROOT_BOUND_CONDITION: number = 60;


const QUOTE_STATUS_TEXT_MAP = {
  'draft': 'Draft Quote',
  'draft_quote': 'Draft Quote',
  'quote_requested': 'Quote Requested',
  'quote_updated': 'Quote Summary',
  'quote_modified': 'Quote Modified',
  'quote_outdated': 'Quote Expired',
  'quote_cancelled': 'Quote Archived',
  'order_cancelled': 'Quote Archived',
  'quote_request_updated': '',
}
/**
 * Brand IDs of brands that have microsites
 */
const BRAND_MICROSITE_IDS = [
  484, // brooklyn bedding hospitality,
  486, // parachute hospitality,
  493, // GT linens
  496, // Fluff co
  541, // Dyson
  539, // Brooklinen
  109, // leesa
]

/**
 * Minoan extension Chrome webstore link
 */
const CHROME_EXTENSION_LINK = 'https://chrome.google.com/webstore/detail/minoan/kaonbikphaokagplfljdhmmcgmhlbkhc'

const TEMPORARY_ONBOARDING_SESSION_VARIABLE = 'tempShowOnboarding'

export {
  EMAIL_REGEX,
  PHONE_REGEX,
  STATES,
  URL_REGEX,
  TOAST_MESSAGES,
  GOOGLE_MAP_RESTRICTIONS,
  GOOGLE_MAP_CITY_RESTRICTION,
  ALL_USER_ROLES,
  INVITED_USER_ROLES,
  deliveryStatusKey,
  NON_MINOAN_PARTNER_BRANDID,
  LOCAL_STORAGE_INFO_VARIABLE,
  LOADER_MESSAGES,
  PLACEHOLDER_URL,
  BRANDS_EXCLUDED_FROM_SCRAPING,
  DELAY_1000_MS,
  ROOT_BOUND_CONDITION,
  QUOTE_STATUS_TEXT_MAP,
  BRAND_MICROSITE_IDS,
  CHROME_EXTENSION_LINK,
  TEMPORARY_ONBOARDING_SESSION_VARIABLE,
}
