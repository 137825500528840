import {Injectable} from '@angular/core';
import {QuoteService} from "../quotes/quote.service";
import {CartService} from "../cart/cart.service";
import {WishlistService} from "../wishlist/wishlist.service";
import {map, mergeMap} from "rxjs";
import {CART_WISHLIST_QUOTE} from "../../interfaces/customTypes";
import {AmplitudeService} from "../events/amplitude.service";
import {AuthService, User} from "../auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    private cartService: CartService,
    private wishlistService: WishlistService,
    private quoteService: QuoteService,
  ) { }

  /**
   * Common API for adding a product
   * @param type Place where product is added
   * @param requestBody Request body
   */
  addProduct(type: CART_WISHLIST_QUOTE | 'new-quote', requestBody: any) {
    switch (type){
      case "cart": return this.cartService.submitAddProduct(requestBody);
      case "wishlist": return this.wishlistService.submitAddProduct(requestBody);
      case "quote": return this.quoteService.submitAddProduct(requestBody);
      case "new-quote": return this.addProductNewQuote(requestBody);
      default: return this.cartService.submitAddProduct(requestBody);
    }
  }

  /**
   * Create a draft quote and add 1 product to it
   * @param requestBody Request body
   */
  addProductNewQuote(requestBody: any) {
    return this.quoteService.createNewDraftQuote({}).pipe(
      mergeMap(response => {
        const body = {
          ...requestBody,
          orderId: response.id,
          unixVersion: response.unixVersion
        }
        return this.quoteService.submitAddProduct(body).pipe(
          map((addProductResponse: any) => ({...addProductResponse, ...response}) )
        )
      })
    );
  }

}
