import {Injectable} from '@angular/core';

/**
 * All errors to be shown on HTML pages will be declared in this service
 */
@Injectable({
  providedIn: 'root'
})
export class ErrorsService {

  constructor() { }
  //  Toast Errors
  EMAIL_OR_PASSWORD_IS_WRONG = 'Email or password is incorrect.';
  NO_ACCESS_TO_ACCOUNT = 'You no longer have access to this account. Please contact your account Owner or Admin.'
  NO_ACCESS_TO_ANY_ACCOUNT = 'User does not have access to any account, please contact admin for access.'
  ACCOUNT_BANNED = 'account_banned' // custom error with mailto href on login page
  PASSWORD_NEWLY_CREATED = 'This account has not yet been activated. We just sent you an email with a link to create your password.'
  ACCOUNT_SETUP_KEY_RESEND= 'This account has not yet been activated. We just sent you an email with a link to set up your account.'
  FIELD_REQUIRED = 'This field is required.';
  MAX_LENGTH_50 = 'This value may not exceed 50 characters.';
  PROPERTY_NAME_TAKEN = 'This name is already taken.';
  EMAIL_INVALID = 'Email is invalid.';
  USER_ALREADY_ADDED = 'This user has already been added.';

  CONTACT_NUMBER_INVALID = 'Phone number is invalid.'

  ZIP_CODE_INVALID = 'Zip code is invalid.'
  STATE_INVALID = 'State is invalid.'

  ADDRESS_LABEL_INVALID = 'This name has already been used.'

  GOOGLE_AUTOCOMPLETE_ERROR = 'Please use Google Autocomplete to fill the address.';

  PASSWORD_MISMATCH = 'Passwords do not match.'
  PASSWORD_LENGTH_8CHARACTERS = 'Password must contain at least 8 characters.';
  EMAIL_REQUIRED = 'Email is required.'
  PASSWORD_REQUIRED = 'Password is required.'

  URL_INVALID = 'URL is invalid.'
  URL_NOT_MINOAN_BRAND_PARTNER = 'This product / URL is not from an official Minoan brand partner.'
  QUANTITY_GREATER_THAN_0 = 'Must be > 0.'

  // Errors for MINO-2263
  ADD_PRODUCT_URL_INVALID = 'A valid url is required.'
  ADD_PRODUCT_PRODUCT_NAME_REQUIRED = 'A valid product name is required.'
  ADD_PRODUCT_VARIANT_REQUIRED = 'Specify a variant or select the checkbox above.'
}
