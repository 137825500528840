import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {phoneToViewFormat} from "../../utils/form-utils";
import {onlyOnDesktop, onlyOnMobile} from "../../utils/common-functions";
import {environment} from "../../../environments/environment";
import * as moment from 'moment'
import {CHROME_EXTENSION_LINK} from "../../constants/constants";

import {datadogRum} from "@datadog/browser-rum";

@Injectable({
  providedIn: 'root'
})
export class RandomService {
  isScrollingTop = new BehaviorSubject(false);
  isScrollingTopUpdated: boolean = false;

  showLoader = new BehaviorSubject(false)
  showLoaderText = new BehaviorSubject('')

  // Subject to be updated when item is added using add-item-modal from header component
  itemAdded = new Subject<boolean>()

  // Subject to be updated when options are updated
  optionsChanged = new Subject<boolean>()

  constructor() { }


  /**
   * Format phone number for display
   * @param phone Phone number
   */
  formatPhoneForView(phone : string | undefined){
    return phoneToViewFormat(phone!)
  }

  get onPhone(){
    return onlyOnMobile()
  }

  get onDesktop(){
    return onlyOnDesktop()
  }

  /**
   * Returns comma separated string of list objects
   * @param list String list
   */
  returnCommaSeparatedStrings(list: string[]){
    return list.join(', ')
  }

  /**
   * Update loader state
   * @param value {boolean} True = Loader on, False = Loader off
   */
  updateLoader(value: boolean){
    this.showLoader.next(value);
  }

  updateLoaderText(text:any){
    this.showLoaderText.next(text);
  }

  /**
   * Remove USA string from the order
   * @param location - Location string
   *  @returns {string} - Location string without USA
   */
  removeUSA(location : string | undefined): string{
    if(location){
      let locationString = location;
      if(locationString.includes('USA')){
        locationString = locationString.replace(', USA','');
      }
      if(locationString.includes('United States')){
        locationString = locationString.replace(', United States','').replace('United States', '');
      }
      if(locationString.includes('United States of America')){
        locationString = locationString.replace(', United States of America','');
      }
      if(locationString.includes('US')){
        locationString = locationString.replace(', US','');
      }

      return locationString;
    } else {
      return '';
    }
  }

  /**
   * Get weblink of a brand for modal
   * @param link Weblink string
   */
  getWeblink(link: string){
    if(link){
      return link.replace('http://', '').replace('https://', '').replace('/', '').replace('www.', '')
    }
    return ''
  }

  /**
   * Returns true if Window width <= 767px (mobile)
   */
  get onMobilePhone(): boolean{
    return window.innerWidth <= 767;
    // return window.matchMedia('(max-width):767px').matches
  }


  /**
   * Get Exceeded file size in KB or MB
   * @param fileSizeInBytes holds the value of current added file.
   * @param threshold holds the value of maximum limit.
   */
  getFileSize(fileSizeInBytes: number, threshold: number): any {
    if(fileSizeInBytes > threshold){
      return  this.convertBytes(fileSizeInBytes - threshold);
    }
  }

  /**
   * Converts Bytes into KB or MB.
   * @param bytes
   */
  convertBytes(bytes:number) {
    const units = {
      B: 1,
      KB: 1024,
      MB: 1024 * 1024
    };

    let unit = 'B';
    let convertedValue = bytes;

    if (bytes >= units.MB) {
      unit = 'MB';
      convertedValue = bytes / units.MB;
    } else if (bytes >= units.KB) {
      unit = 'KB';
      convertedValue = bytes / units.KB;
    }
    return  convertedValue + unit
  }

  /**
   * Getter for Chrome Extension Link
   */
  get chromeExtensionLink(): string {
    return CHROME_EXTENSION_LINK;
  }

  get groupDemoCalendlyLink(): string {
    return 'https://calendly.com/charlottee/minoan-s-demo-and-q-a-session?month=2024-03'
  }

  /**
   * Check Williams Sonoma Brands based on brand IDs
   * @param brandId Brand ID
   */
  checkWSBrands(brandId: number): boolean {
    return environment.wSBrands.includes(brandId)
  }

  dateFormatter = {

    dayFormatter: (exactDate: any) => {
      let day = moment.utc(exactDate).format('dddd');
      let month = this.monthAbbreviations[moment.utc(exactDate).format('M')];
      let date = moment.utc(exactDate).format('D');

      let formattedDate = day + ', ' + month + ' ' + date;

      return formattedDate;
    },

    monthFormatter: (exactDate: string) => {
      let month = this.monthAbbreviations[moment.utc(exactDate).format('M')];
      let date = moment.utc(exactDate).format('D');
      let year = moment.utc(exactDate).format('Y');

      let formattedDate = month + ' ' + date + ', ' + year;

      return formattedDate;
    },

    dateRangeFormatter: (startDate: string, endDate: string) => {
      let startMonth = this.monthAbbreviations[moment.utc(startDate).format('M')];
      let startDateExtracted = moment.utc(startDate).format('D');
      let endMonth = this.monthAbbreviations[moment.utc(endDate).format('M')];
      let endDateExtracted = moment.utc(endDate).format('D');
      let startYear = moment.utc(startDate).format('Y')
      let endYear = moment.utc(endDate).format('Y');

      let formattedDate = startMonth + ' ' + startDateExtracted
      formattedDate += startYear == endYear ? '' : `, ${startYear}`
      formattedDate += ' - ' + endMonth + ' ' + endDateExtracted + ', ' + endYear;

      return formattedDate;
    },

    dayRangeFormatter: (startDate: any, endDate: any) => {
      let startDay = moment.utc(startDate).format('dddd');
      let startMonth = this.monthAbbreviations[moment.utc(startDate).format('M')];
      let startDateExtracted = moment.utc(startDate).format('D');
      let endDay = moment.utc(endDate).format('dddd');
      let endMonth = this.monthAbbreviations[moment.utc(endDate).format('M')];
      let endDateExtracted = moment.utc(endDate).format('D');

      let formattedDate = startDay + ', ' + startMonth + ' ' + startDateExtracted + ' - ' + endDay + ', ' + endMonth + ' ' + endDateExtracted;

      return formattedDate;
    },
  }

  timeFormatter = {
    formatTime: (start: string | null, end: string | null) => {
      let startTime = '';
      let endTime = '';
      if (end) {
        endTime = moment(end, "HH:mm").format("h:mm A");
        endTime = endTime.replace(/AM/g, 'a.m').replace(/PM/g, 'p.m');
      }

      if (start) {
        startTime = moment(start, "HH:mm").format("h:mm A");
        startTime = startTime.replace(/AM/g, 'a.m').replace(/PM/g, 'p.m');
      }

      let timeRange = '';

      if (start !== null && end !== null) {
        timeRange = `${startTime}. - ${endTime}.`;
      }

      return timeRange;
    }
  }

  monthAbbreviations: any = {
    '1': 'Jan.',
    '2': 'Feb.',
    '3': 'Mar.',
    '4': 'Apr.',
    '5': 'May',
    '6': 'June',
    '7': 'July',
    '8': 'Aug.',
    '9': 'Sept.',
    '10': 'Oct.',
    '11': 'Nov.',
    '12': 'Dec.',
  }

  /**
   * Check if the window width is greater than 776.
   *
   * @return {boolean} true if the window width is greater than 776, false otherwise
   */
  isWindowWidthGreater(){
    return window.innerWidth > 776;
  }


  /**
   * Initialize Data dog rum
   */
  initDataDogRum(){
    datadogRum.init({
      applicationId: environment.dataDogApplicationId,
      clientToken: environment.dataDogClientToken,
      site: 'us5.datadoghq.com',
      service: `b2b-${environment.env}`,
      env: environment.env,
      sessionSampleRate: 100,
      sessionReplaySampleRate:  environment.env === 'prod' ? 50 : 20 ,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
  }

  /**
   * Set User in Datadog Rum for identification.
   * @param id for user id
   * @param email for user email address
   */
  setDataDogRumUser(id:number,email:string){
    datadogRum.setUser({
      id: id.toString(),
      email: email,
    })
  }

  /**
   * Klaviyo Script injector method
   * @param url holds the url of klaviyo script
   */
  injectKlaviyoScript(url: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const scriptElement = document.createElement('script');
      scriptElement.src = url;

      scriptElement.onload = () => resolve();
      scriptElement.onerror = (error: any) => {
        if (typeof error === 'string') {
          reject(new Error(error));
        } else {
          reject(new Error('Error loading Klaviyo script'));
        }
      };

      document.body.appendChild(scriptElement);
    });
  }

}
