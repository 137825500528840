import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PluralSingularPipe} from './pluralSingular/plural-singular.pipe';
import {ApplyFnPipe} from './applyFn/apply-fn.pipe';


@NgModule({
  declarations: [
    PluralSingularPipe,
    ApplyFnPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    PluralSingularPipe,
    ApplyFnPipe
  ]
})
export class PipesModule { }
