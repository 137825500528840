import {Injectable} from '@angular/core';
import {CHROME_EXTENSION_LINK, TEMPORARY_ONBOARDING_SESSION_VARIABLE} from "../../constants/constants";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class GettingStartedService {


  GETTING_STARTED_CONTENT = [
    {
      heading: 'Install the Shopping Assistant',
      description: 'Our Google Chrome Extension is the easiest way to add items to your Minoan Cart as you shop the web. Only available on desktop.',
      link: '',
      amplitudeTitle: 'install_shopping_assistant',
    },
    {
      heading: 'Browse discounts and shop for items',
      description: 'Explore our brands & discounts, then shop online with any of our 150+ brand partners.',
      link: '',
      amplitudeTitle: 'browse_and_shop',
    },
    {
      heading: 'Add items to your Minoan Cart',
      description: 'To access Minoan\'s insider pricing, add items directly to your Minoan Cart as you shop.',
      link: '',
      amplitudeTitle: 'add_items',
    },
    {
      heading: 'Request a quote',
      description: 'Your quote will be ready within a couple of hours and will include discounted Minoan pricing & delivery estimates.',
      link: '',
      amplitudeTitle: 'request_a_quote',
    },
    {
      heading: 'Checkout and save',
      description: 'Review your quote, make any necessary changes, and checkout directly on Minoan.',
      link: '',
      amplitudeTitle: 'checkout_and_save',
    },
  ]

  stepLinks: string[] = [
    CHROME_EXTENSION_LINK,
    'https://www.loom.com/share/6d89efa180784018bf1474719b06d43f?sid=7da2f2f1-e9f4-4cb1-a9cb-2920d61bc52f',
    'https://www.loom.com/share/acc50a8bf17447889a05119945b2fe77?sid=12b50036-2ce3-4807-b455-cefa807db9a5',
    'https://www.loom.com/share/974737f6400d475cb36bbf41e0040cb5?sid=81b761c5-6dfc-4d03-bcd7-f06f8cac3890',
    'https://www.loom.com/share/1f6e41ae6df946918f73063b72d7e00f?sid=7788bafe-acd9-4c0b-b54b-bc1013f13336',
  ]

  ftuxStatus: BehaviorSubject<any[]> = new BehaviorSubject([false, false, false, false, false])
  currentActiveIndex = -1

  // To show ftux card temporarily till user refreshes the page
  showFtuxTemporarily: boolean = false;

  constructor(

  ) {
    this.GETTING_STARTED_CONTENT = this.GETTING_STARTED_CONTENT.map((each, index) => ({
      ...each,
      link: this.getStepLink(index)
    }))
  }

  get currentFTUXStatus() {
    return this.ftuxStatus.getValue()
  }

  set currentFTUXStatus(status: boolean[]) {
    this.currentActiveIndex = -1
    for (let i = 1; i < status.length; i++) {
      if (!status[i]) {
        this.currentActiveIndex = i
        break;
      }
    }
    this.ftuxStatus.next(status)
  }

  /**
   * Get link of CTA on for a step
   * @param index Index of step
   */
  getStepLink(index: number) {
    return this.stepLinks[index] ? this.stepLinks[index] : 'javascript:void(0);';
  }

  /**
   * Creates a session storage variable to allow pass onboarding guard and show user the onboarding screens
   */
  allowTemporaryOnboarding() {
    sessionStorage.setItem(TEMPORARY_ONBOARDING_SESSION_VARIABLE, 'true')
  }
}
