import {Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {throttleTime} from 'rxjs/operators';

@Directive({
  selector: '[appDoubleClick]'
})
export class DoubleClickDirective implements OnInit, OnDestroy {

  @Input()
  throttleTime = 2000;

  @Output()
  throttledClick = new EventEmitter();

  private clicks = new Subject();
  private subscription: Subscription | undefined;

  constructor() { }

  ngOnInit() {
    this.subscription = this.clicks.pipe(throttleTime(this.throttleTime)).subscribe({
      next: (e) => {
        this.emitThrottledClick(e)
      }
    });
  }

  emitThrottledClick(e:any) {
    this.throttledClick.emit(e);
  }

  ngOnDestroy() {
    // this.subscription.unsubscribe();
    if (this.subscription === undefined) {
    } else {
      this.subscription.unsubscribe();
    }
  }

  @HostListener('click', ['$event'])
  clickEvent(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }

}
