import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaxLengthDirective} from "./maxLengthDirective/max-length.directive";
import {OnlyDigitsDirective} from "./onlyDigitsDirective/only-digits.directive";
import {ToUpperCaseDirective} from "./toUpperCaseDirective/to-upper-case.directive";
import {DoubleClickDirective} from "./doubleClickDirective/double-click.directive";
import {TwoDecimalPlacesDirective} from './twoDecimalPlacesDirective/two-decimal-places.directive';
import {DragAndDropDirective} from './dragAndDropDirective/drag-and-drop.directive';
import {GoogleAutocompleteDirective} from "./googleAutocompleteDirective/google-autocomplete.directive";


@NgModule({
  declarations: [
    MaxLengthDirective,
    OnlyDigitsDirective,
    ToUpperCaseDirective,
    DoubleClickDirective,
    TwoDecimalPlacesDirective,
    DragAndDropDirective,
    GoogleAutocompleteDirective,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    MaxLengthDirective,
    OnlyDigitsDirective,
    ToUpperCaseDirective,
    DoubleClickDirective,
    TwoDecimalPlacesDirective,
    DragAndDropDirective,
    GoogleAutocompleteDirective,
  ]
})
export class DirectivesModule { }
