import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MultiselectListboxComponent} from "./multiselect-listbox/multiselect-listbox.component";
import {QtyInputComponent} from "./qty-input/qty-input.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RippleModule} from "primeng/ripple";
import {ButtonModule} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {DirectivesModule} from "../directives/directives.module";
import {ScrollingModule} from "@angular/cdk/scrolling";


@NgModule({
  declarations: [
    MultiselectListboxComponent,
    QtyInputComponent
  ],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RippleModule,
		ButtonModule,
		InputTextModule,
		DirectivesModule,
    ScrollingModule
	],
  exports: [
    MultiselectListboxComponent,
    QtyInputComponent
  ]
})
export class FormElementsModule { }
